import { useEffect, useState } from "react";
import "./Contact_Page.scss";
import { Layout } from "../../Components/Common/Layout/Layout";
import { contactData } from "../../mockData/contact";
import axios from "axios";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";
import Thankyou from "../../Assets/contact/thankyou.svg";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";

const seoData = {
  title: "Contact Zeero: Get in Touch with Our Team ",
  desc: "Get in touch with Zeero for inquiries, support, or collaborations. Reach out to us via email at info@zeero.us. We're here to help you succeed.",
  canonical: "contact/all",
};

export const Contact_Page = () => {
  useEffect(() => {
    const ele = document.getElementById("main-d");
    ele.scrollIntoView();
  }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d">
        <Layout>
          <div className="contact-main">
            <LeftSide data={contactData?.leftSide} />
            <RightSide data={contactData?.rightSide} />
          </div>
        </Layout>
      </div>
    </>
  );
};

const LeftSide = ({ data }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [viewLoader, setViewLoader] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const alphabeticOnly = value.replace(/[^a-zA-Z]/g, "");
      setFormData((prevState) => ({
        ...prevState,
        [name]: alphabeticOnly,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    setViewLoader(true);
    e.preventDefault();
    try {
      const endPoint = "/user/contact/all";
      const data = await axios.post(endPoint, formData);
      if (data) {
        setSubmitSuccess(true);
      }
    } catch (err) {
      console.log("submit request error ==>", err);
    } finally {
      setViewLoader(false);
    }
    console.log(formData);
  };

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {submitSuccess ? (
        <SuccessSubmit />
      ) : (
        <div className="left-main">
          <div className="title">{data?.title}</div>
          <div className="desc">{data?.desc}</div>
          <div className="form-container">
            <form onSubmit={handleSubmit} className="contact-form">
              <label htmlFor="fullName">
                Full Name<span className="required">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Full Name"
              />

              <label htmlFor="email">
                Email<span className="required">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
              />

              <label htmlFor="subject">
                Subject <span className="required">*</span>
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="Subject"
              />

              <label htmlFor="message">
                Message <span className="required">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Message"
              />

<button type="submit" style={{backgroundColor:'#0B52D4',marginBottom:'15px'}}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const SuccessSubmit = () => {
  return (
    <div className="thank-you">
      <div>
        <img src={Thankyou} alt="" />
      </div>
      <div className="first">Thankyou for your interest in Zeero!</div>
      <div className="second">
        Your request has been submitted. You can expect a response within 48
        hours. We look forward to connect with you soon!
      </div>
    </div>
  );
};

const RightSide = ({ data }) => {
  return (
    <div className="right-main">
      <div className="top-image">
        <img src={data?.image} alt="" />
      </div>
      <div className="location">
        {data?.address?.map((item, index) => (
          <div key={index} className="item">
            {item.Image && (
              <div className="item-image">
                <item.Image />
              </div>
            )}
            <div>
              <div className="title">{item?.title}</div>
              <div className="desc">{item?.desc}</div>
              <div>{item?.phone}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
