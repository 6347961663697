import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

export const ZeeroAIPlatform = ({ data }) => {
  const [videoUrl, setVideoUrl] = useState(data?.backgroundImageUrl);
  const [videoKey, setVideoKey] = useState(0); // Key to force re-render

  useEffect(() => {
    if (data) {
      setVideoUrl(data?.backgroundImageUrl);
      setVideoKey(prevKey => prevKey + 1); // Change key when URL updates
    }
  }, [data]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {videoUrl && (
       <video
       key={videoKey} // Changing key forces React to re-render the video
       autoPlay
       muted
       playsInline
       preload="metadata"
       loop // Ensures the video loops continuously
       crossOrigin="anonymous"
       style={{
         width: "100%",
         height: "100%",
         objectFit: "cover",
       }}
     >
       <source src={isMobile ? data?.backgroundImageUrlMobile : videoUrl} type="video/mp4" />
     </video>
     
      )}
    </Box>
  );
};
