import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";
import { useInView } from "react-intersection-observer";
import styles from "./Blogs.module.scss";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const BlogSection = ({ data }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const cardWidth = isMobile ? window.innerWidth - 40 : window.innerWidth / 3.5;

  // Auto-scroll for mobile
  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        if (sliderRef.current && data?.blogs?.length > 0) {
          sliderRef.current.slickNext();
        }
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isMobile, data?.blogs?.length]);

  const [componentInView, setComponentInView] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  if (inView && !componentInView) {
    setComponentInView(true);
  }

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    lazyLoad: "progressive",
    cssEase: "ease-out",
    centerMode: isMobile,
    centerPadding: isMobile ? "20px" : "0px",
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <Box sx={{ width: "100%", py: { md: 6, xs: 4 }, textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 4,
          padding: { xs: "0px 10px 30px 10px", md: "32px" },
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#212121",
            fontFamily: "Poppins",
            fontSize: { md: "24px", xs: "16px" },
            fontWeight: 600,
            letterSpacing: "0.2px",
            textAlign: "center",
          }}
        >
          Explore Our Blogs
        </Typography>

        {!isMobile && (
          <Box sx={{ position: "absolute", right: 20, display: "flex", gap: 1 }}>
            <IconButton
              onClick={handlePrev}
              sx={{ color: "#0B52D4" }}
              disabled={currentSlide === 0}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{ color: "#0B52D4" }}
              disabled={
                currentSlide >= data?.blogs.length - (isMobile ? 1 : 3)
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <Slider
        ref={sliderRef}
        {...settings}
        className={`${styles.sliderBlogs} custom-blog-slider`} // ← Add global class
        sx={{
          padding: { lg: "60px", md: "45px", sm: "30px", xs: "30px" },
        }}
        color="#0B52D5"
      >
        {data?.blogs.map((blog, index) => (
          <Box
          key={index}
          sx={{
            textAlign: "start",
            height: "100%",
          }}
        >
        
            <img
              src={blog.image}
              alt={blog.title}
              style={{
                width: "100%",
                borderBottomRightRadius: "60px",
                display: "block",
              }}
            />
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#212121",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {blog.date} • {blog.readTime}
                </Typography>
                <Typography
  component="a"
  href={blog.link}
  sx={{
    display: "flex", // Make it a flex container
    alignItems: "center", // Align text and icon in a row
    gap: "5px", // Space between text and icon
    color: "#0B52D4",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "none",
    transition: "all 0.3s ease", // Smooth transition effect
    "&:hover":{
      color: "#194CAA",
    },
    "&:hover .arrow-icon": {
      transform: "translateX(5px)", // Moves icon to the right on hover
    },
  }}
>
  Read more
  <Box
    component="span"
    className="arrow-icon"
    sx={{
      transition: "transform 0.3s ease",
      display: "flex",
    }}
  >
    <DoubleArrowIcon sx={{ fontSize: "18px",color:'#0B52D4' }} />
  </Box>
</Typography>
              </Box>
             
              <Typography
                sx={{
                  color: "#212121",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "26px",
                  mt: 1,
                }}
              >
                {blog.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default BlogSection;
