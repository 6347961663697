import React from 'react';
import { SeoTags } from '../../../Components/Common/SeoTags/SeoTags';
import { Layout } from '../../../Components/Common/Layout/Layout';
import { useClientLandingPageInfo } from '../ClientLandingPageInfo/ClientLandingPageInfo';
import { ClientLandingPageSection1 } from '../ClientLandingPageSections/ClientlandingPageSection1/ClientLandingPageSection1';
import WhatWeDo from '../ClientLandingPageSections/WhatWeDo/WhatWeDo';
import { WhyChooseZeero } from '../ClientLandingPageSections/WhyChooseZeero/WhyChooseZeero';
import WhatWeOffer from "../ClientLandingPageSections/WhatWeOffer/WhatWeOffer.jsx";

import TechHiringProcess from '../ClientLandingPageSections/TechHiring/TechHiring.jsx';
import JobSeekers from '../ClientLandingPageSections/JobSeekers/JobSeekers.jsx';
import ResultsSpeak from '../ClientLandingPageSections/ResultSpeak/ResultSpeak.jsx';
import BlogSection from '../ClientLandingPageSections/Blogs/Blogs.jsx';
import { Faqs } from '../../../Components/Common/Faqs/Faqs.jsx';
import { Banner } from '../../../Components/Home/Banner/Banner.jsx';
import { TestimonialsSlider } from "../../../Components/ClientPage/TestimonialsSlider/TestimonialsSlider";
import { useMediaQuery } from '@mui/material';
import { ZeeroAIPlatform } from '../../../Components/ClientPage/ZeeroAIPlatform/ZeeroAIPlatform.jsx';
import Bubbles from '../ClientLandingPageSections/bubbles/Bubbles.jsx';
import BubblesMobile from '../ClientLandingPageSections/bubbles/BubbleMobile/BubblesMobile.jsx';

const seoData = {
    title: "Build Top Tech Teams Fast | Streamlined Hiring with Zeero  ",
    desc: "Access pre-vetted developers & scale your software development team with ease. Start building, managing, & scaling your team today! Zeero offers flexible solutions.",
    canonical: "client",
};

const ClientLandingPageHome = () => {
    const isMobile = useMediaQuery('(max-width:768px)'); // Check if screen width is <= 768px
    const ClientlandingPageInfo=useClientLandingPageInfo();
    
    

    return (
        <>
            <SeoTags seoData={seoData} />
            <Layout>
                <ClientLandingPageSection1 data={ClientlandingPageInfo?.section1} />
                
                {/* Hide WhatWeDo on mobile view */}
               <WhatWeDo  /> 

                <WhyChooseZeero ComponentData={ClientlandingPageInfo?.ourServicesNew || {}} />
                <WhatWeOffer features={ClientlandingPageInfo?.features || {}} />

                {/* <Discover data={ClientlandingPageInfo?.zeeroAIPlatformData} /> */}
                <ZeeroAIPlatform data={ClientlandingPageInfo?.zeeroAIPlatformData} />
                {isMobile ?<BubblesMobile  data={ClientlandingPageInfo?.technology}/>: <Bubbles  data={ClientlandingPageInfo?.technology}/>}
               
                {/* {<Technology  data={ClientlandingPageInfo?.technology}/>} */}
                <TechHiringProcess  data={ClientlandingPageInfo?.hiringSteps}/>
                <TestimonialsSlider data={ClientlandingPageInfo?.testimonialsSliderData} />
                <JobSeekers data={ClientlandingPageInfo?.jobSpeaks} />
                <ResultsSpeak  data={ClientlandingPageInfo?.resultSpeaks} />
                <BlogSection data={ClientlandingPageInfo?.blogs} />
                <Faqs faqData={ClientlandingPageInfo?.faqs} />
                <Banner data={ClientlandingPageInfo?.banner} />
            </Layout>
        </>
    );
}; 

export default ClientLandingPageHome;
