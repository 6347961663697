import React, { useState, useEffect, useRef } from "react";
import "./Banner.scss";
import backgroundImage from "../../../Assets/home/CTABanner.webp";
import { useNavigate } from "react-router-dom";

export const Banner = ({ data }) => {
  const navigate = useNavigate();
  const bannerRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting && !hasAnimated) {
          setHasAnimated(true); // Mark animation as triggered
          observer.unobserve(entry.target); // Stop observing after animation
        }
      },
      {
        threshold: 0.3, // Trigger when 30% of the element is visible
      }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, [hasAnimated]); // Run effect only if animation hasn't been triggered before

  return (
    <div
      ref={bannerRef}
      className="banner"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="banner-content">
        {/* Left Side */}
        <div className="left-content">
          <h2 className={`animated-text ${hasAnimated ? "visible" : ""}`}>
            <span>{data?.heading}</span> <br />
            <span>{data?.heading2}</span>
          </h2>
        </div>

        {/* Right Side */}
        <div className="right-content">
          <p className={`animated-text ${hasAnimated ? "visible" : ""}`}>
            {data?.desc}
          </p>
          <button
            className="get-started-button"
            onClick={() => navigate(data?.navigateTo)}
          >
            {data?.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
