import React, { useState, useEffect } from "react";
import { Button, Stack, Typography, Box } from "@mui/material";
import theme from "../../../../Components/Theme/Theme";

export const ClientLandingPageSection1 = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect mobile screens

  console.log(data,"data")

  // Function to handle screen resize
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Stack
      direction={{ xs: "column", lg: "row-reverse" }} // Image first on mobile, side-by-side on desktop
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        height: { lg: "75vh", xs: "auto" },
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#fff",
       boxShadow: "6px 6px 4px 0px rgba(79, 79, 79, 0.04)"
      }}
    >
      {/* Image Content (First on Mobile, Right Side on Desktop) */}
      <Box
        sx={{
          width: { xs: "100%", lg: "50%" }, // Full width on mobile, 50% on desktop
          height: { xs: "auto", lg: "100%" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft:{xs:'15px'}
        }}
      >
        <img
          src={data?.image} // Use image instead of video
          alt="Landing Section"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            // objectFit: "cover", // Ensures the image covers the box properly
          }}
        />
      </Box>

      {/* Text Content (Below Image on Mobile, Left Side on Desktop) */}
      <Box
        sx={{
          width: { xs: "100%", lg: "50%" }, // Full width on mobile, 50% on desktop
          textAlign: { xs: "center", lg: "left" },
          padding: { xs: "20px", lg: "60px" }, // Adjust spacing
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", lg: "flex-start" }, // Center-align on mobile
        }}
      >
        {/* Heading Section - CONNECT • INVITE • SCALE */}
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", lg: "nowrap" }, // Stack on mobile, single row on desktop
            // gap: { xs: 1, lg: 2 },
            justifyContent: { xs: "center", lg: "flex-start" },
            alignItems: "center",
          }}
        >
          {["CONNECT", "INNOVATE", "SCALE"].map((item, index) => (
            <Typography
              key={index}
              variant="h4"
              sx={{
                color: "#212121",
                fontFamily: "Poppins",
                fontSize: { lg: "24px", xs: "16px" },
                fontWeight: 700,
                letterSpacing: "1.2px",
                textTransform: "uppercase", // Ensures consistent styling
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item}
              {index < 2 && ( // Add "•" between words except after last one
                <Box
                  sx={{
                    mx: 1.5, // Space between text and dot
                    width: "8px",
                    height: "8px",
                    backgroundColor: `${theme?.blueButton}`,
                    borderRadius: "50%",
                  }}
                />
              )}
            </Typography>
          ))}
        </Box>

        {/* Description Section */}
        <Typography
          component="p"
          sx={{
            color: `${theme?.lightBlack}`,
            fontFamily: "Poppins",
            fontSize: { xs: "16px", lg: "22px" },
            fontWeight: 400,
            lineHeight: "1.5",
            // letterSpacing: "0.2px",
            marginBottom: { xs: "20px", lg: "40px" },
            textAlign: { xs: "center", lg: "start" },
          }}
        >
          {data?.description}
        </Typography>

        {/* Buttons Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
            gap: { xs: 2, md: 3 },
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            href={data?.buttonUrl1}
            sx={{
              padding: { xs: "10px 20px", lg: "12px 30px" },
              fontSize: { xs: "14px", lg: "16px" },
              height: { lg: "54px", xs: "40px" },
              backgroundColor: `${theme?.blueButton}`,
              ":hover": { backgroundColor: `${theme?.blueButtonHover}` },
              width: { xs: "100%", md: "auto" },
              textTransform: "none", // Prevents uppercase text
            }}
          >
            {data?.buttonText1}
          </Button>

          <Button
            variant="contained"
            href={data?.buttonUrl2}
            sx={{
              padding: { xs: "10px 20px", lg: "12px 30px" },
              fontSize: { xs: "14px", lg: "16px" },
              height: { lg: "54px", xs: "40px" },
              backgroundColor: `${theme?.greenButton}`,
              ":hover": { backgroundColor: `${theme?.greenButtonHover}` },
              width: { xs: "100%", md: "auto" },
              textTransform: "none", // Prevents uppercase text
            }}
          >
            {data?.buttonText2}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};
