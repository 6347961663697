import React from "react";
import { Box, Typography } from "@mui/material";
import Waves from "./Waves"; // Import the Waves component

const WaveBanner = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "250px",
        backgroundColor: "#0C4FC1",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Waves Background - Bottom Layer */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0, // Lowest depth
        }}
      >
        <Waves
          lineColor="#fff"
          waveSpeedX={0.02}
          waveSpeedY={0.01}
          waveAmpX={40}
          waveAmpY={20}
          friction={0.9}
          tension={0.01}
          maxCursorMove={120}
          xGap={12}
          yGap={36}
        />
      </Box>

      {/* Content Box - Above Waves */}
      <Box
        sx={{
          position: "relative",
          background: "#4F8EFF",
          padding: "16px 32px",
          borderRadius: "12px",
          textAlign: "center",
          maxWidth: "600px",
          zIndex: 1, // Above waves but below any future top-layer content
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#FFF", fontSize: "20px" }}
        >
          "Five rounds, one mission—finding the best with Zeero!"
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#FFF", marginTop: "8px", fontSize: "14px" }}
        >
          Our structured five-round assessment identifies top-tier developers by
          testing technical expertise, logical reasoning, and problem-solving
          abilities, ensuring companies hire the best in the industry.
        </Typography>
      </Box>
    </Box>
  );
};

export default WaveBanner;
