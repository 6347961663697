import "./WhyChooseZeero.scss";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography, Grid, IconButton, useMediaQuery } from "@mui/material";

export const WhyChooseZeero = ({ ComponentData }) => {
  const [flippedIndex, setFlippedIndex] = useState(null);

  return (
    <div className="whyChooseZeero" style={{ backgroundColor: "#F9F9F9" }}>
      <div className="ourservicesnew">
        <div className="heading2">{ComponentData?.heading}</div>

        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {ComponentData?.cards?.map((card, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ marginBottom: "16px" }}>
                <FlipCard
                  frontContent={card?.title}
                  backContent={card?.desc}
                  imageData={card?.image}
                  isFlipped={flippedIndex === index}
                  onHover={(isHovered) => {
                    if (isHovered) setFlippedIndex(index);
                    else setFlippedIndex(null);
                  }}
                  index={index}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

const FlipCard = ({ frontContent, backContent, imageData, isFlipped, onHover, index }) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleIconClick = () => {
    if (isMobile) {
      onHover(!isFlipped);
    }
  };

  return (
    <Box
      sx={{
        width: { xs: "320px", md: "384px" },
        height: "256px",
        padding: "2px",
      }}
      onMouseEnter={() => !isMobile && onHover(true)}
      onMouseLeave={() => !isMobile && onHover(false)}
    >
      {/* Card Body */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d",
          transition: "transform 0.6s",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {/* Front Side */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#C3D8FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={imageData}
            alt="Front"
            sx={{
              width: { xs: "292px", md: "358px" },
              height: "232px",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Back Side */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#C3D8FF",
            transform: "rotateY(180deg)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "12px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              padding: "16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                textAlign: "center",
                color: "#333",
                fontSize: { xs: "14px", md: "16px" },
              }}
            >
              {backContent}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Footer with Title & Arrow */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            color: "#333",
            fontSize: { xs: "14px", md: "16px" },
            flexGrow: 1,
          }}
        >
          {frontContent}
        </Typography>

        <IconButton
          onClick={handleIconClick}
          sx={{
            color: "#0B52D4",
            display: { xs: "inline-flex", md: "flex" },
          }}
        >
          {isFlipped ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};
