import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./HireDeveloper.module.scss";
import { Grid, Stack, Typography } from "@mui/material";
import { Padding } from "@mui/icons-material";
import { useMediaQuery } from '@mui/material';
export const HireDeveloper = ({ hireDeveloperData }) => {
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.7 });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.7 });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.7 });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.7 });
  const { ref: ref5, inView: inView5 } = useInView({ threshold: 0.7 });
  

  const [currentIndex, setCurrentIndex] = useState(1);
  const [visibleStepIndex, setVisibleStepIndex] = useState(1); // State for visible number on the left side
  const iconRef = useRef(null); // Ref for the icon
  const numberRefs = useRef([]); // Refs for the number elements

  useEffect(() => {
    let newIndex = currentIndex; // Keep the previous value by default
  
    if (inView5) newIndex = 4;
    else if (inView4) newIndex = 3;
    else if (inView3) newIndex = 2;
    else if (inView2) newIndex = 1;
  
    // Update state only if there's a change
    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
    }
  }, [inView1, inView2, inView3, inView4, inView5, currentIndex]);
  

  useEffect(() => {
    // Update the visibleStepIndex when a step becomes visible on the right side
    if (inView5) setVisibleStepIndex(5);
    else if (inView4) setVisibleStepIndex(4);
    else if (inView3) setVisibleStepIndex(3);
    else if (inView2) setVisibleStepIndex(2);
    else if (inView1) setVisibleStepIndex(1);
  }, [inView1, inView2, inView3, inView4, inView5]);

  useEffect(() => {
    // Move the icon to the previous number (the number before the current one in view)
    if (numberRefs.current[currentIndex - 1] && iconRef.current) {
      const numberRect =
        numberRefs.current[currentIndex - 1].getBoundingClientRect();
      const numberRowRect =
        numberRefs.current[0].parentNode.getBoundingClientRect();

      // Calculate the distance and move the icon
      const translateX = numberRect.left - numberRowRect.left;
      iconRef.current.style.transform = `translateX(${translateX}px)`;
    }
  }, [currentIndex]);

  const isMobile = useMediaQuery('(max-width: 600px)');


  useEffect(() => {
    if (iconRef.current) {
      if (isMobile) {
        // Stop any movement or animation on mobile
        iconRef.current.style.position = 'static';
        iconRef.current.style.animation = 'none'; // Stop CSS animation
        iconRef.current.style.transform = 'none'; // Remove any transform logic
      } else {
        // Re-apply movement logic on non-mobile screens if necessary
        iconRef.current.style.position = 'absolute'; // Or whatever position you were using
        iconRef.current.style.animation = ''; // Restore animation
      }
    }
  }, [isMobile]);

  return (
    <Stack
    className={styles.container}
    sx={{
      padding: {
        lg: '60px',  // Padding for large screens
        sm: '30px', 
        xs:'30px' // Padding for small screens
      },
    }}
  >
      {/* Left side: Title, description, and numbers */}
      
      <div className={styles.subContainer}>
      <Grid container>
      <Grid item lg={6} md={6} xs={12} className={styles.leftSide} sx={{paddingRight:{lg:'100px',sm:'0px',md:'0px',xs:'0px'}}}>
  <Typography
    sx={{
      color: '#FFF !important', // Text color with higher priority
      fontFamily: 'Poppins !important', // Force font family
      fontSize: {lg:'32px !important',sm:'24px !important',xs:'24px !important',md:'24px !important'}, // Force font size
      fontStyle: 'normal !important', // Force font style
      fontWeight: '700 !important', // Force font weight
      lineHeight: 'normal !important', // Force line height
      letterSpacing: '0.2px !important', // Force letter spacing
      textAlign: {lg: 'left', sm: 'center', xs: 'center', md: 'center'} // Center on smaller screens
    }}
  >
    {hireDeveloperData?.title}
  </Typography>
  <Typography
    sx={{
      color: '#FFF !important', // Text color with higher priority
      fontFamily: 'Poppins !important', // Force font family
      fontSize: {lg:'21px !important',sm:'14px !important',xs:'14px !important',md:'14px !important'}, // Force font size
      fontStyle: 'normal !important', // Force font style
      fontWeight: '700 !important', // Force font weight
      lineHeight: 'normal !important', // Force line height
      letterSpacing: '0.2px !important', // Force letter spacing
      textAlign: {lg: 'left', sm: 'center', xs: 'center', md: 'center'} // Center on smaller screens
    }}
  >
     {hireDeveloperData?.description}
  </Typography>





        
         

  <div className={styles.numberRow}>
        {[1, 2, 3, 4, 5].map((number, index) => (
          <div
            key={index}
            className={`${styles.numberCircle} ${
              isMobile || visibleStepIndex >= number ? styles.animateIn : ''
            }`}  // On mobile, always show numbers, otherwise use visibleStepIndex
            ref={(el) => (numberRefs.current[index] = el)}  // Assign ref to each number
          >
            {number < 10 ? `0${number}` : number}
          </div>
        ))}
      </div>

          {/* Moving Icon */}
          <div className={styles.iconWrapper}>
            <img
              src={hireDeveloperData?.animationIcon}
              alt="Person Icon"
              ref={iconRef}
              className={styles.movingIcon}
            />
          </div>
        </Grid>

        {/* Right side: Steps */}
        <Grid item lg={6} md={6} xs={12} className={styles.rightSide}>
  {[ref1, ref2, ref3, ref4, ref5].map((ref, index) => (
    <div
      key={index}
      className={`${styles.step} ${
        [inView1, inView2, inView3, inView4, inView5][index]
          ? styles.animateIn
          : ""
      } `} // Add conditional class for the last element
      style={{
        marginBottom:
          index === hireDeveloperData?.steps.length - 1
            ? "0px !important"
            : "26px", // Apply 0px margin-bottom only for the last step
      }}
      ref={ref}
    >
      <div className={styles.stepHeader}>
        <div className={styles.stepNumber}>{index + 1}</div>
        <h4>{hireDeveloperData?.steps[index].title}</h4>
      </div>
      <p className={styles.stepDesc}>
        {hireDeveloperData?.steps[index].description}
      </p>
      {index < hireDeveloperData?.steps.length - 1 && (
        <div
          className={`${styles.line} ${
            [inView1, inView2, inView3, inView4, inView5][index + 1]
              ? styles.visible
              : ""
          }`}
        />
      )}
    </div>
  ))}
</Grid>

        </Grid>
      </div>
    </Stack>
  );
};
