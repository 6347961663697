import React,{useState,useEffect} from "react";
import "./BubblesMobile.css"; // Import CSS for mobile styling
import { Typography, Box } from "@mui/material";

const BubblesMobile = ({ data }) => {
  const [xminus, setXminus] = useState(0);
const [xplus, setXplus] = useState(0);
const [x1, setX1] = useState(0);
const [x2, setX2] = useState(0);
const [yminus, setYminus] = useState(0);
const [yplus, setYplus] = useState(0);
const [y1, setY1] = useState(0);
const [y2, setY2] = useState(0);

useEffect(() => {
  if (window.innerWidth < 400) {
    setXminus(10);
    setXplus(-10);
    setX1(20);
    setX2(5);

    setYminus(10);
    setYplus(-10);
    setY1(20);
    setY2(-5);
  }
}, []);
  const leftBubblePositions = [
    { x: 65-xminus, y: -20 },
    { x: -15-xplus, y: 30 },
    { x: -15-xplus, y: 110 },
    { x: 60-xminus, y: 160 },
    { x: 140-x1, y: 100 },
    { x: 140-x1, y: 30 },
    { x: 60-x2, y: 60 },
  ];

  const rightBubblePositions = [
    { x: 60, y: -20 },
    { x:135-yminus, y: 30 },
    { x: -15-yplus, y: 35 },
    { x: 135-yminus, y: 105 },
    { x: 65, y: 160 },
    { x: 60-y2, y: 65 },
    { x: -15-yplus, y: 100 },
  ];

  return (
    <div className="bubbles-mobile-wrapper-mobile">
      {/* Title */}
      <Box className="heading-mobile">
        <Typography
          sx={{
            color: "#212121",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            letterSpacing: "0.2px",
            marginBottom: "30px",
            fontSize:'14px'
          }}
        >
          Zeero discovers talents with expert proficiency across multiple technologies
        </Typography>
      </Box>

      {/* Bubbles & Image Container */}
      <div className="content-mobile-container-mobile">
        {/* Left Bubbles */}
        <div className="bubbles-mobile left">
          {leftBubblePositions.map((pos, index) => (
            <div
              className="bubble-mobile"
              key={`left-${index}`}
              style={{
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                background: bubbles[index]?.bg,
              }}
            >
              <Typography className="bubble-text-mobile">{bubbles[index]?.text}</Typography>
            </div>
          ))}
        </div>

        {/* Center Image */}
       

        {/* Right Bubbles */}
        <div className="bubbles-mobile right">
          {rightBubblePositions.map((pos, index) => (
            <div
              className="bubble-mobile"
              key={`right-${index}`}
              style={{
                right: `${pos.x}px`,
                top: `${pos.y}px`,
                background: bubbles[index + 7]?.bg,
              }}
            >
              <Typography className="bubble-text-mobile">{bubbles[index + 7]?.text}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BubblesMobile;

const bubbles = [
  { s: 1, bg: "#4978EE", text: "Python" },
  { s: 1, bg: "#42BA97", text: "AI" },
  { s: 1, bg: "#7F7F96", text: "ML" },
  { s: 1, bg: "#DB3F6F", text: "Java" },
  { s: 1, bg: "#8374D9", text: "Node JS" },
  { s: 1, bg: "#CF3AD4", text: "Java Script" },
  { s: 1, bg: "#4AA9E3", text: "PHP" },

  { s: 1, bg: "linear-gradient(to bottom, #F6BE42, #DCB159)", text: "Ruby" },
  { s: 1, bg: "linear-gradient(to bottom, #673AB7, #3F51B5)", text: "Django" },
  { s: 1, bg: "#8374D9", text: "Mongo DB" },
  { s: 1, bg: "#DB3F6F", text: "SQL" },
  { s: 1, bg: "#7F7F96",text: "React JS" },
  { s: 1, bg: "#42BA97", text: "Kotlin" },
  { s: 1, bg: "#4978EE", text: "Type Script" },
];
