import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import Slide2Right from './Slide2Right';

const Slide2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row', // Change layout for mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: '#fff', // Optional background color
        padding: {md:'2rem',xs:'1rem 1rem 1rem 2rem'},
        gap: {md:'2rem',xs:'1rem'}, 
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          width: isMobile ? '100%' : '50%', // Full width on mobile
         
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* Flexibility Box */}
        <Box
          sx={{
            display: 'inline-block',
            padding: '1px',
            borderRadius: '18px',
            border: '1px solid #062B6E',
            background: '#D4FFDF',
            marginBottom: '1rem',
            width: '104px',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#4F4F4F',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            Flexibility
          </Typography>
        </Box>

        {/* Heading */}
        <Typography
          sx={{
            color: '#062B6E',
            fontFamily: 'Poppins',
            fontSize: {md:"22px",xs:"16px"},
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            marginBottom: '1rem',
            textAlign: 'left',
          }}
        >
          Scale your tech teams easily
        </Typography>

        {/* Description */}
        <Typography
          sx={{
            color: '#4F4F4F',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '30px',
            textAlign: 'left',
          }}
        >
          We offer the flexibility and agility required to quickly scale and adapt your tech teams, whether they operate remotely, in a hybrid model, or on-site, ensuring your business can efficiently respond to changing needs and demands.
        </Typography>
      </Box>

      {/* Right Section (Image or Content) */}
      <Box
        sx={{
          width: isMobile ? '100%' : '50%', // Full width on mobile
          height: '100%',
          display: 'flex',
          justifyContent:isMobile?"start": 'center',
          alignItems: 'center',
          backgroundColor: '#eaeaea', // Temporary background to highlight the section
          marginTop: isMobile ? '1.5rem' : '0', // Add spacing on mobile
        }}
      >
        <Slide2Right />
      </Box>
    </Box>
  );
};

export default Slide2;
