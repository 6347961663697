import React from "react";
import { Box, Typography, Card, CardContent, useMediaQuery } from "@mui/material";


const TechHiringProcess = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box sx={{ p: {md:4,xs:2}, textAlign: "center", backgroundColor: "#f9f9f9" }}>
      {/* Main Heading */}
      <Typography
        sx={{
          color: "#212121",
          fontFamily: "Poppins",
          fontSize: isMobile?"16px":"24px",
          fontWeight: 600,
          letterSpacing: "0.2px",
          mb: 4,
        }}
      >
        Tech hiring made easy
      </Typography>

      {/* Steps Container */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          // gap: 2,
        }}
      >
        {data?.arr?.map((step, index) => (
          <React.Fragment key={index}>
            {/* Step Card */}
            <Card
              sx={{
                borderRadius: "24px",
                border: "1px solid #D5DBE3",
                backgroundColor: "#FFF",
                width: "220px",
                height: "250px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                textAlign: "center",
                position: "relative",
                boxShadow: 1,
                overflow: "visible",
                backgroundColor:"#FFFFFF"
              }}
            >
              {/* Semi-Circle Step Number (Inside the Card) */}
              <Box
  sx={{
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#0D47A1",
    color: "#fff",
    width: "64px",
    height: "32px",
    borderRadius: "0 0 30px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "bold",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Added shadow
  }}
>
  {step.step}
</Box>


              <CardContent sx={{ mt: 4, flexGrow: 1,padding:'12px 8px 10px 8px' }}>
                {/* Icon */}
                <img
                  src={step.icon}
                  alt={step.title}
                  style={{ width: "50px", height: "50px", margin: "0 auto 10px" }}
                />

                {/* Title */}
                <Typography
  sx={{
    color: "#212121",
    fontFamily: "Poppins",
    fontSize: { xs: "16px", md: "18px" }, // 16px on mobile, 18px on larger screens
    fontWeight: 600,
    letterSpacing: "0.2px",
  }}
>
  {step.title}
</Typography>


                {/* Description */}
                <Typography
                  sx={{
                    color: "#4F4F4F",
                    fontFamily: "Poppins",
                    fontSize: { xs: "13px", md: "14px" }, // 16px on mobile, 18px on larger screens
                    fontWeight: 400,
                    letterSpacing: "0.2px",
                    mt: 1,
                  }}
                >
                  {step.description}
                </Typography>
              </CardContent>
            </Card>

            {/* Arrows between cards replaced with TechHiring5 image */}

            {index !== data?.arr?.length-1  && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "100%",
                  minHeight: "30px",
                  marginRight:isMobile?"0px":'30px',
                   marginBottom:isMobile?"28px":'0px'
                }}
              >
                <img
                  src={data?.TechHiring5}
                  alt="Step Divider"
                  style={{
                    width: "40px",
                    height: "auto",
                    margin: "2px",
                    transform: isMobile ? "rotate(90deg)" : "none"
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default TechHiringProcess;
