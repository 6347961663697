import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import "./ResultSpeak.css";

const ResultsSpeak = ({ data }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const elements = document.querySelectorAll(".fade-up");
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target); // Stop observing after first trigger
          }
        });
      },
      { threshold: 0.2 }
    );

    elements.forEach((element) => observer.observe(element));

    return () => elements.forEach((element) => observer.unobserve(element));
  }, []);

  return (
    <Box
      ref={sectionRef}
      className="results-section"
      sx={{
        backgroundImage: `url(${data?.ResultSpeakBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#FFF",
        textAlign: "center",
        py: 5,
        px: { xs: 2, md: 5 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Heading */}
      <Typography
        className="fade-up"
        sx={{
          fontFamily: "Poppins",
          fontSize: { xs: "16px", md: "24px" },
          fontWeight: 600,
          letterSpacing: "0.2px",
          mb: { md: 4, xs: 3 },
          textAlign: "center",
        }}
      >
        Our results speak for us
      </Typography>

      {/* Statistics Grid */}
      <Box
        className="stats-grid"
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
          gap: { md: 8, xs: 4 },
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          alignItems: "center",
        }}
      >
        {data?.stats.map((stat, index) => (
          <Box
            key={index}
            className="fade-up"
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              className="fade-up"
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "18px", xs: "16px" },
                fontWeight: 500,
                letterSpacing: "0.2px",
                mb: 1,
              }}
            >
              {stat.title}
            </Typography>
            <Typography
              className="fade-up"
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "28px", xs: "24px" },
                fontWeight: 700,
                letterSpacing: "0.2px",
                mb: 1,
              }}
            >
              {stat.number}
            </Typography>
            <Typography
              className="fade-up"
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "16px", xs: "14px" },
                fontWeight: 500,
                letterSpacing: "0.2px",
              }}
            >
              {stat.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ResultsSpeak;
