import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slide1 from "./Slides/Slide1";
import Slide2 from "./Slides/Slide2";
import Slide3 from "./Slides/Slide3";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const WhatWeDoMobile = () => {
  const slides = [<Slide1 />, <Slide2 />,<Slide3 />];//

//   useEffect(() => {
//     // Pin sections with ScrollTrigger
//     gsap.utils.toArray(".section").forEach((panel) => {
//       ScrollTrigger.create({
//         trigger: panel,
//         start: "top top", // Pin the section when it reaches the top
//         end: "bottom top", // Unpin when the bottom of the section reaches the top
//         pin: true, // Enable pinning
//         pinSpacing: false, 
//       });
//     });
//   }, []);

  return (
    <Box sx={{ overflow: "hidden", position: "relative" }}>
      {/* Navbar */}
      
      {/* Smooth Wrapper */}
      <Box id="smooth-wrapper" sx={{ position: "relative" }}>
        <Box id="smooth-content">
          {slides.map((section, index) => (
            <Box
              key={index}
              className="section"
              sx={{
                top: 0,
                // width: "100%",
                // minHeight: "100vh",
                border: "2px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              {section}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Additional Content */}
      
    </Box>
  );
};

export default WhatWeDoMobile;