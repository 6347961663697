import "./CandidateLogin.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleSso } from "./GoogleSso";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LinkedInSso } from "./LinkedInSSo";
import { useCookies } from "react-cookie";
import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";
import { afterLogin } from "../../Common/flowAfterLogin";
import { resolve } from "../../Common/resolve";
import { TopLogo } from "../../Components/topLogo/topLogo";
import laptopImage from "../../Assets/candidate_login/laptop.svg";
import { Layout } from "../../Components/Common/Layout/Layout";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";
import { CircularProgress } from "@mui/material";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";

const seoData = {
  title: "Zeero Developer Login ",
  desc: "Sign in to your Zeero developer account. Update your profile, complete your vetting process, access project, and unlock exciting opportunities. ",
  canonical: "login",
};

export const CandidateLogin = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const detectDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isTouchCapable = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const hasMouse = window.matchMedia("(pointer: fine)").matches; // Detects mouse presence
    const devicePixelRatio = window.devicePixelRatio || 1; // Detects screen density
  
    // Refined iOS detection: Only classify as "Mobile/Tablet (iOS)" if width < 1024
    if (
      (/iPad|iPhone|iPod/.test(userAgent) || 
      (navigator.platform === "MacIntel" && isTouchCapable)) &&
      width < 1024
    ) {
      return "Mobile/Tablet (iOS)";
    }
  
    // Detect Android devices
    if (/Android/.test(userAgent)) {
      return /Mobile/.test(userAgent) ? "Mobile (Android)" : "Tablet (Android)";
    }
  
    // Detect Windows tablets but exclude touchscreen laptops
    if (/Windows NT/.test(userAgent) && isTouchCapable && !hasMouse && width < 1024 && devicePixelRatio > 1.5) {
      return "Tablet (Windows)";
    }
  
    // **Fix: Ensure touchscreen laptops are detected correctly**
    if (/Windows NT/.test(userAgent) && isTouchCapable && hasMouse && width >= 1024) {
      return "Laptop with Touchscreen";
    }
  
    // Detect MacBooks with Touchscreen (if applicable)
    if (/MacIntel/.test(navigator.platform) && isTouchCapable && hasMouse) {
      return "MacBook with Touchscreen";
    }
  
    // Default to desktop
    return "Desktop";
  };
  
  
  
  useEffect(() => {
    const updateDeviceType = () => {
      const deviceType = detectDeviceType();
      console.log(deviceType,"deviceType")
      if (deviceType.includes("Mobile") || deviceType.includes("Tablet")) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    // Run detection on component mount
    updateDeviceType();
  
    // Add a listener for viewport changes
    window.addEventListener('resize', updateDeviceType);
  
    // Cleanup the event listener
    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);
  
    

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth <= 650) {
  //       setIsMobile(true);
  //     } else {
  //       setIsMobile(false);
  //     }
  //   }

  //   window.addEventListener("resize", handleResize);
  //   handleResize(); // Call once initially
  //   // console.log("deviceType==>", deviceType());

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      {loading && <GlobalLoader />}
      <div className="login-wrapper">
        {isMobile ? (
          <MobileScreen />
        ) : (
          <div className="login-main">
            <LeftImage />
            <LoginWrapper setLoading={setLoading} />
          </div>
        )}
      </div>
    </>
  );
};

const MobileScreen = () => {
  return (
    <Layout>
      <div className="mobile-ui">
        <div className="laptop">
          <img src={laptopImage} alt="" />
        </div>
        <div className="desc">
          Please login through the Desktop as the mobile version is unavailable.
        </div>
        <button className="okay" onClick={() => (window.location.href = "/")}>
          Okay
        </button>
      </div>
    </Layout>
  );
};

const LeftImage = () => {
  return (
    <div className="left-image">
      <div className="item-content">
        <TopLogo />
        <div className="logo-candidate">
          <img src="./images/ILLUSTRATION.svg" alt="" />
        </div>
        {/* <div className="desc-main">Your Career, Your Way!!</div> */}
      </div>
    </div>
  );
};

const LoginWrapper = ({ setLoading }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState(cookies?.username ? cookies.username : "");
  const [password, setPassword] = useState(
    cookies?.password ? cookies.password : ""
  );

  const [remember, setRemember] = useState(
    cookies?.remember ? cookies.remember : false
  );
  const [viewError, setViewError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleViewError = () => {
    setViewError(true);
    // setTimeout(() => {
    //   setViewError(false);
    // }, 5000);
  };

  const handleLoginClick = async (e) => {
    e?.preventDefault();
    setViewError(false);
    setLoading(true);
    try {
      const data = {
        username: email,
        password: password,
      };
      const urlEndpoint = "/candidate/login/credentials";
      const resPromise = axios.post(urlEndpoint, data).then((res) => res.data);
      // console.log("resPromise", resPromise);
      const res = await resolve(resPromise);
      if (res?.error?.response?.data) {
        throw new Error(res.error?.response?.data);
      }
      // console.log("handleLoginClick ==>", res?.error?.response?.data);
      if (remember) rememberCookie();
      if (res) {
        // sessionStorage.setItem("email", email);
        localStorage.setItem("email", email);
        await afterLogin();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      handleViewError();
      console.log("handleLoginClick ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const rememberCookie = () => {
    setCookie("username", email, {
      path: "http://localhost:3000",
    });
    setCookie("password", password, {
      path: "http://localhost:3000",
    });
    setCookie("remember", remember, {
      path: "http://localhost:3000",
    });
  };

  useEffect(() => {
    // Replace with your actual API call
    const checkAuthentication = async () => {
      try {
        const data = {
          email: localStorage.getItem("email"),
        };
        const response = await axios.post("/validate/auth", data);
        const data1 = response?.data;
        if (data1?.isAuthenticated) navigate("/assessment/home");
        // Assuming the API returns an object with an isAuthenticated field
      } catch (error) {
        console.error("Error checking authentication:==>", error);
      }
    };

    checkAuthentication();
  }, []);

  return (
    <div className="login-main-container">
      <div className="login-container">
        <h2 className="title">Login</h2>
        <form onSubmit={handleLoginClick}>
          <div className="input-group">
            <label>Email</label>
            <input
              className="email-input"
              type="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              fullWidth
              required
              autoComplete="false"
              style={{ backgroundColor: "white" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {viewError && (
              <div className="errorBox">
                <ErrorIcon />
                <span>Invalid username or password</span>
              </div>
            )}
          </div>
          <div className="actions">
            <div className="checkbox-group">
              <input
                type="checkbox"
                value={remember}
                onChange={(event) => {
                  setRemember(event.target.checked);
                }}
                autoComplete="on"
              />
              <label htmlFor="remember">Remember me?</label>
            </div>
            <Link className="forgot-link" to={"/forgot-password"}>
              Forgot password?
            </Link>
          </div>

          <button className="login-btn">Login</button>
        </form>
        <div className="divider">
          <span>Or</span>
        </div>
        <div className="sso-flow">
          <GoogleOAuthProvider clientId="475139665425-6fb5qnqavost26gnibro23shf2mlmqni.apps.googleusercontent.com">
            <GoogleSso />
          </GoogleOAuthProvider>
        </div>
        <div className="sso-flow">
          <LinkedInSso />
        </div>
        <div className="register-link">
  Don't have an account? <Link to="/register" style={{ textDecoration: 'none' }}>Register</Link>
</div>
      </div>
    </div>
  );
};