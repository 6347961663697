import { useState } from "react";
import { ReactComponent as Dropdown } from "../../../Assets/SVG/Dropdown.svg";
import "./Faqs.scss";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export const Faqs = ({ faqData={}, title = "", desc = "", mainData = [] }) => {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div className="main-faq">
      <div className="wrapper">
        <div className="title">{faqData?.title}</div>
        {faqData?.desc && <div className="desc">{faqData?.desc}</div>}
        {/* options */}
        {faqData?.mainData?.[0]?.header && (
          <div className="options">
            {faqData?.mainData?.map((item, index) => (
              <div
                key={index}
                onClick={() => setSelectedOption(index)}
                className={`${selectedOption === index ? "selected" : ""}`}
              >
                {item?.header}
              </div>
            ))}
          </div>
        )}
        {/* options */}
        {/* faqs */}
        <div>
          <QuesAndAns data={faqData?.mainData?.[selectedOption]?.questions} />
        </div>
        {/* faqs */}
      </div>
    </div>
  );
};

const QuesAndAns = ({ data }) => {
  const [active, setActive] = useState(-1);
  const handleActive = (index) => {
    if (active === index) setActive(null);
    else setActive(index);
  };
  return (
    <div className="faq-data">
      {data?.map((item, index) => (
        // <div key={index} className="ques-ans">
        <div
          key={index}
          onClick={() => handleActive(index)}
          className="ques-ans"
        >
          <div className="ques-arrow">
            <div className="ques">{item?.ques}</div>
            <div
              className="arrow"
              // style={{ transform: active === index ? "rotate(180deg)" : "" }}
            >
             { active === index ?<KeyboardArrowUpOutlinedIcon />:<KeyboardArrowDownOutlinedIcon/>}
            </div>
          </div>
          {active === index && <div className="ans">{item?.ans}</div>}
        </div>
      ))}
    </div>
  );
};
