import React, { useEffect, useRef, useState } from "react";
import { Noise } from "noisejs";
import    "./Bubbles.css"; // Import CSS for styling
import { Typography, Box } from "@mui/material";

const NOISE_AMOUNT = 8; // Subtle movement
const NOISE_SPEED = 0.0025; // Smoother floating animation

const noise = new Noise(Math.random());

const Bubbles = ({ data }) => {
  const animationRef = useRef();
  const [isReady, setReady] = useState(false);

  const bubbles = [
    { s: 1, bg: "#4978EE", text: "Python" },
    { s: 1, bg: "#42BA97", text: "AI" },
    { s: 1, bg: "#7F7F96", text: "ML" },
    { s: 1, bg: "#DB3F6F", text: "Java" },
    { s: 1, bg: "#8374D9", text: "Node JS" },
    { s: 1, bg: "#CF3AD4", text: "JavaScript" },
    { s: 1, bg: "#4AA9E3", text: "PHP" },
  
    { s: 1, bg: "linear-gradient(to bottom, #F6BE42, #DCB159)", text: "Bitbucket" },
    { s: 1, bg: "linear-gradient(to bottom, #673AB7, #3F51B5)", text: "Django" },
    { s: 1, bg: "#8374D9", text: "MongoDB" },
    { s: 1, bg: "#DB3F6F", text: "SQL" },
    { s: 1, bg: "#7F7F96",text: "React JS" },
    { s: 1, bg: "#42BA97", text: "Kotlin" },
    { s: 1, bg: "#4978EE", text: "TypeScript" },
  ];

  // Fixed predefined offsets for better spacing
  const leftBubblePositions = [
    { x: 180, y: -30 },  // Top left
    { x: -150, y: 70 }, // Top right
    { x: 100, y: 70 },       // Middle far left
    { x: -10, y: 80 },   // Middle
    { x: 50, y: -10 },     // Middle far right
    { x: 100, y: 80 },    // Bottom left
    { x: 170, y: 50 },   // Bottom right
  ];
  

  const rightBubblePositions = [
    { x: 100, y: -30 },   // Top left
    { x: 160, y: 70 },  // Top right django
    { x: -160, y: 120 },        // Middle far left // mongo db
    { x: -90, y: -60 },    // Middle sql
    { x: -220, y: 80 },      // Middle far right
    { x: 30, y: 85 },     // Bottom left
    { x: 100, y: 50 },    // Bottom right // Typescripr
  ];
  

  const leftBubbles = bubbles.slice(0, 7).map((bubble, index) => ({
    ...bubble,
    ...leftBubblePositions[index], // Assign fixed positions
    noiseSeedX: Math.random() * 1000,
    noiseSeedY: Math.random() * 1000,
  }));

  const rightBubbles = bubbles.slice(7, 14).map((bubble, index) => ({
    ...bubble,
    ...rightBubblePositions[index], // Assign fixed positions
    noiseSeedX: Math.random() * 1000,
    noiseSeedY: Math.random() * 1000,
  }));

  useEffect(() => {
    setTimeout(() => setReady(true), 200);
    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  function animate() {
    animateBubbles(leftBubbles, "left");
    animateBubbles(rightBubbles, "right");
    animationRef.current = requestAnimationFrame(animate);
  }

  function animateBubbles(bubblesArray, side) {
    bubblesArray.forEach((bubble, index) => {
      // Increment noise values
      bubble.noiseSeedX += NOISE_SPEED;
      bubble.noiseSeedY += NOISE_SPEED;

      // Generate small floating effect
      const noiseX = noise.simplex2(bubble.noiseSeedX, 0) * NOISE_AMOUNT;
      const noiseY = noise.simplex2(bubble.noiseSeedY, 0) * NOISE_AMOUNT;

      const element = document.getElementById(`${side}-bubble-${index}`);
      if (element) {
        element.style.transform = `translate(${bubble.x + noiseX}px, ${bubble.y + noiseY}px) scale(${bubble.s})`;
      }
    });
  }

  return (
    <div className="bubbles-wrapper">
      {/* Title */}
      <Box className="heading">
        <Typography
          sx={{
            color: "#212121",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontWeight: 600,
            letterSpacing: "0.2px",
            marginBottom: "50px",
          }}
        >
          Zeero discovers talents with expert proficiency across multiple technologies
        </Typography>
      </Box>

      {/* Content Section */}
      <div className="content-container">
        {/* Left Bubbles */}
        <div className="bubbles left">
          {leftBubbles.map((bubble, index) => (
            <div
              className="bubble"
              id={`left-bubble-${index}`}
              key={`left-${index}`}
              style={{
                opacity: isReady ? 1 : 0,
                background: bubble.bg,
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  fontWeight: 600,
                  letterSpacing: "0.2px",
                }}
              >
                {bubble.text}
              </Typography>
            </div>
          ))}
        </div>

        {/* Center Image */}
        <div className="image-container">
          <img src={data.illu} alt="illustration" />
        </div>

        {/* Right Bubbles */}
        <div className="bubbles right">
          {rightBubbles.map((bubble, index) => (
            <div
              className="bubble"
              id={`right-bubble-${index}`}
              key={`right-${index}`}
              style={{
                opacity: isReady ? 1 : 0,
                background: bubble.bg,
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  fontWeight: 600,
                  letterSpacing: "0.2px",
                }}
              >
                {bubble.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bubbles;

// **Updated Bubble Data**

