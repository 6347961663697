import {
  BrowserRouter,
  Routes,
  Route,
  useRoutes,
  useNavigate,
  Navigate,useLocation
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { CandidateLogin } from "../Pages/CandidateLogin/CandidateLogin";
import { CandidateWelcome } from "../Pages/CandidateWelcome/CandidateWelcome";
import { Home } from "../Pages/Home/Home";
import { PersonalDetails } from "../Pages/CandidateDetails/PersonalDetails/PersonalDetails";
import { ProfessionalDetails } from "../Pages/CandidateDetails/ProfessionalDetails/ProfessionalDetails2";
import { CustomerSupport } from "../Pages/CustomerSupport/CustomerSupport";
import { CandidateOffersActive } from "../Pages/CandidateOffers/CandidateActiveOffers";
import { IssueTracker } from "../Pages/IssueTracker/IssueTracker";
import { AssessmentHome } from "../Pages/AssessmentPages/AssessmentHome/AssessmentHome";
import { AssessmentRound1 } from "../Pages/AssessmentPages/AssessmentRound1/AssessmentRound1";
import { AssessmentRound2 } from "../Pages/AssessmentPages/AssessmentRound2/AssessmentRound2";
import { ForgotPasswprd } from "../Pages/ForgotPasswprd/ForgotPasswprd";
import { RegisterCandidate } from "../Pages/RegisterCandidate/RegisterCandidate";
import { AssessmentRound3 } from "../Pages/AssessmentPages/AssessmentRound3/AssessmentRound3";
import { useEffect, useState } from "react";
import axios from "axios";
import { AssessmentRound4 } from "../Pages/AssessmentPages/AssessmentRound4/AssessmentRound4";
import { AssessmentRound5 } from "../Pages/AssessmentPages/AssessmentRound5/AssessmentRound5";
import { CandidateOffersExpired } from "../Pages/CandidateOffers/CandidateExpiredOffers";
import { Home_Developer } from "../Pages/Home_Developer/Home_Developer";
import { Home_Client } from "../Pages/Home_Client/Home_Client";
import { Contact_Page } from "../Pages/Contact_Page/Contact_Page";
import { Contact_Client } from "../Pages/Contact_Client/Contact_Client";
import { AlwaysForwardToRound2 } from "../Pages/AssessmentPages/AssessmentRound2/AlwaysForwardToRound2";
import { IssueConversation } from "../Pages/IssueConversation/IssueConversation";
import { ClientPage } from "../Pages/Home_Client_2/Home_Client";
import CandidateInfo from "../Pages/CandidateInfo/CandidateInfo";
import { SignupClient } from "../Pages/SignupClient/SignupClient";
import Sidebar from "../Components/SideBar/SideBar";
import { IconButton,AppBar,Drawer,Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';     
// import { ClientLogin } from "../Pages/ClientLogin/ClientLogin";
import { ClientLogin } from "../Pages/ClientLogin/ClientLogin2";
import SearchTalent from "../Pages/SearchTalent/SearchTalent";
import ShortlistCandidates from "../Pages/ShortlistCandidates/ShortlistCandidates"
import { ClientHeader } from "../Components/ClientPage/ClientHeader/ClientHeader";
import ClientProfile from "../Pages/ClientProfile/ClientProfile";
import LiveTranscription from "../LiveTrans/LiveTranscription";
import EyeProctoring from "../Pages/EyeProctoring/EyeProctoring";
import ClientLandingPageHome from "../Pages/ClientLandingPage/ClientLandingPageHome/ClientLandingPageHome";


export const AllRoutes = () => {

  return (
    <BrowserRouter>
      {/* <GoogleTagManagerScript /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/developer" element={<Home_Developer />} />
        {/* <Route exact path="/client" element={<Home_Client />} /> */}
        <Route exact path="/client" element={<ClientPage />} />
        <Route exact path="/contact/all" element={<Contact_Page />} />
        <Route exact path="/contact/client" element={<Contact_Client />} />
        <Route exact path="/login" element={<CandidateLogin />} />
        {/* <Route exact path="/client-login" element={<ClientLogin />} /> */}
        <Route exact path="/client-login" element={<ClientLogin />} />
        <Route exact path="/sign-in" element={<CandidateLogin />} />
        <Route exact path="/linkedin" element={<LinkedInCallback />} />
        <Route exact path="/forgot-password" element={<ForgotPasswprd />} />
        <Route exact path="/register" element={<RegisterCandidate />} />
        <Route exact path="/sign-up" element={<RegisterCandidate />} />
        <Route exact path="/candidate-info" element={<CandidateInfo />} />
        <Route exact path="/signup-client" element={<SignupClient />} />
        <Route exact path="/liveTrans" element={<LiveTranscription />} />
        <Route exact path="/eye" element={<EyeProctoring />} />
        <Route exact path="/clientlanding" element={<ClientLandingPageHome />} />

        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};


const ProtectedRoutes = () => {
  const location = useLocation();
  console.log(location)

  return (
    <Routes>
      {location.pathname.startsWith('/client') ? (
        <Route path="/*" element={<ClientRoutes />} />
      ) : (
        <Route path="/*" element={<SubRoutes />} />
      )}
    </Routes>
  );
};

const SubRoutes = () => {
  console.log("subroutes")
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // Replace with your actual API call
    const checkAuthentication = async () => {
      try {
        const data = {
          email: localStorage.getItem("email"),
        };
        const response = await axios.post("/validate/auth", data);
        const data1 = response?.data;
        if (data1?.isAuthenticated) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error checking authentication:==>", error);
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  const renderSubRoutes = useRoutes([
    { path: "/welcome", element: <CandidateWelcome /> },
    { path: "/offers/active", element: <CandidateOffersActive /> },
    { path: "/offers/expired", element: <CandidateOffersExpired /> },
    { path: "/issue-tracker", element: <IssueTracker /> },
    { path: "/issue-conversation/:id", element: <IssueConversation /> },
    { path: "/details/personal", element: <PersonalDetails /> },
    { path: "/details/professional", element: <ProfessionalDetails /> },
    { path: "/support-and-faqs", element: <CustomerSupport /> },
    { path: "/assessment/home", element: <AssessmentHome /> },
    { path: "/assessment/round1", element: <AssessmentRound1 /> },
    { path: "/assessment/gotoRound2", element: <AlwaysForwardToRound2 /> },
    { path: "/assessment/round2", element: <AssessmentRound2 /> },
    { path: "/assessment/round3", element: <AssessmentRound3 /> },
    { path: "/assessment/round4", element: <AssessmentRound4 /> },
    { path: "/assessment/round5", element: <AssessmentRound5 /> },
  
  ]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" />;
  }
 
  return (
   <>
      {renderSubRoutes}
      </>
   
  
  );
};


const ClientRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current location

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const data = {
          email: localStorage.getItem("email"),
        };
        const response = await axios.post("/validate/auth", data);
        const data1 = response?.data;
        if (data1?.isAuthenticated) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error checking authentication:==>", error);
        setIsAuthenticated(false);
      }
    };

    // checkAuthentication();
  }, []);

  const renderSubRoutes = useRoutes([
    { path: "client/searchtalent", element: <SearchTalent /> },
    { path: "client/personaldetails", element: <ClientProfile /> },
    { path: "client/shortlisted", element: <ShortlistCandidates /> },
  ]);

  const renderSubRoutesWithoutSidebar = useRoutes([
    { path: "/client/candidate-info", element: <CandidateInfo /> },
  ]);

  // Check if current path is one of the routes that should not have a sidebar
  const isNoSidebarRoute = location.pathname === '/client/candidate-info';

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Loading indicator
  }

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      {/* Render sidebar conditionally */}
      {!isNoSidebarRoute && (
        <div style={{ width: '240px', display: 'block', '@media(max-width: 600px)': { display: 'none' } }}>
          <Sidebar />
        </div>
      )}

      {/* Main content */}
      <div style={{ flex: 1, overflow: 'auto' }}>
        <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
          <ClientHeader isNoSidebarRoute={isNoSidebarRoute} />
          
        </div>
        
        {/* Render the appropriate sub-routes */}
        {isNoSidebarRoute ? renderSubRoutesWithoutSidebar : renderSubRoutes}
      </div>
    </div>
  );
};



