const theme = {
    primary: "#3498db",
    primaryLight: "#5dade2",
    primaryDark: "#2c81ba",
    secondary: "#e74c3c",
    secondaryLight: "#ec7063",
    secondaryDark: "#cb4335",
    white: "#ffffff",
    black: "#000000",
    grayLight: "#f2f4f4",
    grayDark: "#616a6b",
    success: "#28a745",
    warning: "#ffc107",
    error: "#dc3545",
    blueButton:"#0B52D4",
    greenButton:"#34A853",
    lightBlack:'#757575',
    blueButtonHover:"#194CAA",
    greenButtonHover:"#4D8E50",
  };
  
  export default theme;
  