import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./Home_Client.scss";
import  TalentBanner  from "../../Components/ClientPage/Banner/TalentBanner.jsx";
import { clientPageData } from "../../mockData/ClientPageNew";
import { homeData } from "../../mockData/Home";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";
import { WhatZeeroOffers } from "../../Components/ClientPage/WhatZeeroOffers/WhatZeeroOffers";
import { ZeeroAIPlatform } from "../../Components/ClientPage/ZeeroAIPlatform/ZeeroAIPlatform";
import { TopTechnologies } from "../../Components/ClientPage/TopTechnologies/TopTechnologies";
import { TestimonialsSlider } from "../../Components/ClientPage/TestimonialsSlider/TestimonialsSlider";
import { SeamlessPartnership } from "../../Components/ClientPage/SeamlessPartnership/SeamlessPartnership";
import { Layout } from "../../Components/Common/Layout/Layout";
import { CandidateProfiles } from "../../Components/ClientPage/CandidateProfiles/CandidateProfiles";
import { BlogsMain } from "../../Components/Home/Blogs/Blogs";
import { Faqs } from "../../Components/Common/Faqs/Faqs";
import { HireDeveloper } from "../../Components/ClientPage/HireDeveloper/HireDeveloper";
import { Banner } from "../../Components/Home/Banner/Banner";
import Testing1 from "../../Components/ClientPage/CandidateProfiles/ScrollTesting/Testing1"
import ImageSlider from "../../Components/ClientPage/CandidateProfiles/ScrollTesting/Testing1";

const seoData = {
  title: "Build Top Tech Teams Fast | Streamlined Hiring with Zeero  ",
  desc: "Access pre-vetted developers & scale your software development team with ease. Start building, managing, & scaling your team today! Zeero offers flexible solutions.",
  canonical: "client",
};

export const ClientPage = () => {
  // Scroll to the main section on page load
  useEffect(() => {
    const ele = document.getElementById("main-d");
    ele.scrollIntoView();
  }, []);

  // useInView hooks for BlogsMain and Faqs
  const { ref: refBlogs, inView: inViewBlogs } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const { ref: refFaqs, inView: inViewFaqs } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const { ref: refBanner, inView: inViewBanner } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d" className="clientPage">
        <Layout>
          <TalentBanner data={clientPageData?.talentbanner || {}} />
          <WhatZeeroOffers data={clientPageData?.whatZeeroOffersData} />
          <ZeeroAIPlatform data={clientPageData?.zeeroAIPlatformData} />
          <TopTechnologies data={clientPageData?.topTechnologiesData} />
          <TestimonialsSlider data={clientPageData?.testimonialsSliderData} />
          <SeamlessPartnership data={clientPageData?.seamlessPartnershipData} />
          <CandidateProfiles />
          {/* <Testing1 /> */}
          
         
          
          <HireDeveloper
            hireDeveloperData={clientPageData?.hireDeveloperData}
          />

          {/* BlogsMain with Animation */}
          <div
            ref={refBlogs}
            className={`animatedSection ${inViewBlogs ? "animateIn" : ""}`}
          >
            <BlogsMain ComponentData={homeData?.blogs} />
          </div>

          {/* Faqs with Animation */}
          <div
            ref={refFaqs}
            className={`animatedSection ${inViewFaqs ? "animateIn" : ""}`}
          >
            <Faqs faqData={homeData?.faqs} />
          </div>

          {/* banner with Animation */}
          <div
            ref={refBanner}
            className={`animatedSection ${inViewBanner ? "animateIn" : ""}`}
          >
            <Banner  data={homeData?.banner} faqData={homeData?.faqs}  />
          </div>
        </Layout>
      </div>
    </>
  );
};
