import React from "react";
import { Box,useMediaQuery,useTheme } from "@mui/material";
import { useClientLandingPageInfo } from '../../../ClientLandingPageInfo/ClientLandingPageInfo';


const Slide3Right = () => {
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view
    const ClientlandingPageInfo=useClientLandingPageInfo();
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        //height: "400px",
        display: "flex",
        justifyContent:isMobile?"start": 'end',
        alignItems: "end",
        backgroundColor: "white",
      }}
    >
      {/* Solid Circle (Animated) */}
    { !isMobile &&  <Box
        sx={{
          position: "absolute",
          left: "10%", // Adjust position
          top: "75px",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          backgroundColor: "#E3F4FF",
          zIndex: 0,
          animation: "bounceScale 3s infinite ease-in-out", // Add bounce & scale animation
        }}
      ></Box>}

      {/* Border Circle (Rotating) */}
     { !isMobile && <Box
        sx={{
          position: "absolute",
          left: "10%", // Adjust position
          top: "100px",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          border: "2px solid #CAEBFF",
          zIndex: 0,
          animation: "waveRotate 5s infinite ease-in-out",
        }}
      ></Box>}

      {/* Card */}
      <Box
        sx={{
          position: "relative",
          minWidth: {md:'515px',xs:"330px"},
          height: {md:'300px',xs:"220px"},
          borderRadius: "12px",
          background: "#CAEBFF",
          boxShadow:
            "-2px -2px 4px 0px rgba(33, 33, 33, 0.12), 2px 2px 4px 0px rgba(33, 33, 33, 0.12)",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1, // Place above circles
        }}
      >
        {/* Center Image */}
        <Box
          component="img"
          src={ClientlandingPageInfo?.whatWeDo?.Slide3}
          alt="Center Image"
          sx={{
            width: {md:"302px",xs:'207px'},
            height:  {md:"302px",xs:'207px'},
            objectFit: "cover",
          }}
        />
      </Box>

      {/* Animation Keyframes */}
      <style>
        {`
          @keyframes waveRotate {
            0% { transform: rotate(0deg) translateY(0); }
            25% { transform: rotate(90deg) translateY(10px); }
            50% { transform: rotate(180deg) translateY(0); }
            75% { transform: rotate(270deg) translateY(-10px); }
            100% { transform: rotate(360deg) translateY(0); }
          }

          @keyframes bounceScale {
            0% { transform: scale(1) translateY(0); }
            25% { transform: scale(1.05) translateY(-10px); }
            50% { transform: scale(1) translateY(0); }
            75% { transform: scale(1.05) translateY(10px); }
            100% { transform: scale(1) translateY(0); }
          }
        `}
      </style>
    </Box>
  );
};

export default Slide3Right;
