import { useEffect, useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";

// Azure Storage Configuration
const AZURE_STORAGE_ACCOUNT_NAME = "zeeropublicaccount"; // Replace with your actual storage account name
const CONTAINER_NAME = "zeero-prod-assests"; // Your container name
const SAS_TOKEN = "sp=rl&st=2025-02-10T05:19:46Z&se=2036-02-10T13:19:46Z&sv=2022-11-02&sr=c&sig=CvZOvdtPHshbnGca2V1BIbKm0fjoeKBmJ097vLhbEPA%3D"; // Your actual SAS token

export const useFetchAzureAssets = () => {
    const [assets, setAssets] = useState({});

    useEffect(() => {
        const fetchAssets = async () => {
            try {
                if (!SAS_TOKEN) {
                    console.error("Error: SAS Token is missing. Please provide a valid SAS token.");
                    return;
                }

                // Construct BlobServiceClient using SAS Token
                const blobServiceClient = new BlobServiceClient(
                    `https://${AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/?${SAS_TOKEN}`
                );

                const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME);
                let assetMap = {};

                for await (const blob of containerClient.listBlobsFlat()) {
                    if (blob.name.match(/\.(png|jpg|jpeg|svg|webp|mp4)$/i)) {
                        assetMap[blob.name] = `https://${AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${CONTAINER_NAME}/${blob.name}?${SAS_TOKEN}`;
                    }
                }

                setAssets(assetMap);
            } catch (error) {
                console.error("Error fetching assets from Azure:", error);
            }
        };

        fetchAssets();
    }, []);

    return assets;
};
