import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slide1 from "./Slides/Slide1";
import Slide2 from "./Slides/Slide2";
import Slide3 from "./Slides/Slide3";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const WhatWeDoCards = () => {
  const slides = [<Slide1 />, <Slide2 />, <Slide3 />];

  useEffect(() => {
    // Pin sections with ScrollTrigger
    gsap.utils.toArray(".section").forEach((panel, index) => {
      ScrollTrigger.create({
        trigger: panel,
        start: "top top", // Pin the section when it reaches the top
        end: index === 2 ? "top top" : "+=100%", // For the third section, unpin immediately
        pin: true, // Enable pinning
        pinSpacing: false, // Disable pin spacing
        markers: false, // Enable markers for debugging (optional)
      });
    });
  }, []);

  return (
    <Box sx={{ overflow: "hidden", position: "relative" }}>
      {/* Smooth Wrapper */}
      <Box id="smooth-wrapper" sx={{ position: "relative" }}>
        <Box id="smooth-content">
          {slides.map((section, index) => (
            <Box
              key={index}
              className="section"
              sx={{
                top: 0,
                width: "100%",
                minHeight: "100vh", // Ensure each section is at least the height of the viewport
                border: "2px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              {section}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Additional Content */}
      <Box
        sx={{
          height: "0px",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2">This is the next content</Typography>
      </Box>
    </Box>
  );
};

export default WhatWeDoCards;