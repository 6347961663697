import React from 'react';
import WhatWeDoCards from './WhatWeDoCards';
import { Typography, Box ,useMediaQuery} from '@mui/material';
import WhatWeDoMobile  from "./WhatWeDoMobile"

const WhatWeDo = ({data}) => {
  const isMobile = useMediaQuery('(max-width:768px)'); // Check if screen width is <= 768px
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Ensures stacked layout
        alignItems: "center", // Centers content
        textAlign: "center",
        width: "100%", 
      //  paddingTop: { md: "60px", xs: "30px" }, // Adjusts top spacing
      }}
    >
      {/* Section Title */}
      <Typography
        sx={{
          color: "var(--systemGrey-900, #212121)",
          fontFamily: "Poppins",
          fontSize: { md: "24px", xs: "16px" },
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          letterSpacing: "0.2px",
         marginTop: { md: "30px", xs: "20px" }, // Space between title and cards
        }}
      >
        What We Do
      </Typography>

      {/* Cards Component */}
     { isMobile?<WhatWeDoMobile  />:<WhatWeDoCards />}
    </Box>
  );
};

export default WhatWeDo;
