import React, { useState, useEffect } from "react";
import { Button, Stack, Typography, Box } from "@mui/material";

 const TalentBanner = ({data}) => {
  console.log(data,"hahhdhh")
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Set initial state based on the current window width

  // Function to detect mobile screen size
  const handleResize = () => {
    const mobileBreakpoint = 768; // Set a threshold for mobile width
    setIsMobile(window.innerWidth <= mobileBreakpoint);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Check initial window size on load (though we set this above in the initial state)
    handleResize();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }} // Stacked on small screens, side by side on large
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        height: { lg: "90vh", xs: "auto",sm:'100%',md:'100%' }, // 90vh on large screens, auto height on smaller screens
        position: "relative", // For text to be positioned on the video
        overflow: "hidden", // Hide overflow for video
        backgroundColor: "#E0EBFF", // Background color
      
      }}
    >
      {/* Video content */}
      <Box
        sx={{
          width: { xs: "100%", lg: "100%" }, // Full width on all screens
          height: { xs: "100%", sm: "100%", lg: "100%" }, // Control video height on mobile
          display: "flex", // Flex for centering video
          alignItems: "center", // Vertically center video
          justifyContent: "center", // Horizontally center video
          position: "relative", // For text overlay
         
        }}
      >
        <video
          autoPlay
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures video covers the box
           
          }}
        >
          
          <source
            src={isMobile ? data?.videoUrlMobile : data?.videoUrl} // Use mobile URL in mobile mode
            type="video/mp4"
          />
        </video>
      </Box>

      {/* Text content */}
      <Box
        sx={{
          position: { xs: "relative", lg: "absolute" }, // Overlap text on video for large screens
          top: { lg: "50%" }, // Vertically center text on large screens
          left: { lg: "5%" }, // Push text from the left edge on large screens
          transform: { lg: "translateY(-50%)" }, // Proper vertical alignment for large screens
          width: { xs: "100%", lg: "45%" }, // Full width for small screens, smaller on large
          textAlign: { xs: "center", lg: "left" }, // Center text on small screens, left-aligned on large
          zIndex: 2, // Ensure the text is on top of the video
          padding: { xs: "20px", lg: "0" }, // Padding for small screens, no padding on large
          border: "none",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: "#212121", // Apply the requested styles for line1
            fontFamily: "Poppins",
            fontSize: { lg: "24px", sm: "15px", xs: "15px", md: "15px" },
            fontWeight: 700,
            lineHeight: "normal",
            letterSpacing: "1.2px",
            marginBottom: { xs: "10px", lg: "20px" },
          }}
        >
          {data?.line1}
        </Typography>
        <Typography
          component="p"
          sx={{
            color: "#212121", // Updated color
            fontFamily: "Poppins",
            fontSize: { xs: "16px", sm: "16px", lg: "20px", md: "20px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal", // Normal line height
            letterSpacing: "0.2px", // Letter spacing
            marginBottom: { xs: "20px", lg: "40px" }, // Keep existing margin
          }}
        >
          {data?.line2}
        </Typography>

        <Button
          variant="contained"
          href={data?.buttonUrl}
          sx={{
            padding: { xs: "10px 20px", lg: "12px 30px" },
            fontSize: { xs: "14px", lg: "16px" },
            height: { lg: "54px", sm: "40px", xs: "40px", md: "40px" },
            backgroundColor: "#34a853",
            ":hover": {
              backgroundColor: "#2f9048",
            },
          }}
        >
          {data?.buttonText}
        </Button>
      </Box>
    </Stack>
  );
};


export default TalentBanner;
