import React, { useEffect, useState } from "react";
import { Layout } from "../../Components/Common/Layout/Layout";
// import { WhatWeDo } from "../../Components/WhatweDo/WhatweDo";
import { RightImage } from "../../Components/Common/RightImage/RightImage";
import { homeData } from "../../mockData/Home";
import { Faqs } from "../../Components/Common/Faqs/Faqs";
// import { useNavigate } from "react-router-dom";
import "./style.scss";
// import rightArrow from "../../Assets/home/right-blue-arrow.svg";
import { WhatweDoNew } from "../../Components/WhatweDoNew/WhatweDoNew";
import { OurServicesNew } from "../../Components/OurservicesNew/OurServicesNew";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";
import { WhatWeOfferHome } from "../../Components/Home/WhatWeOffer/WhatWeOffer";
import { HiringProcess } from "../../Components/Home/HiringProcess/HiringProcess";
import { BlogsMain } from "../../Components/Home/Blogs/Blogs";
import { Banner } from "../../Components/Home/Banner/Banner";

const seoData = {
  title: "Zeero",
  desc: "Grow your tech team with Zeero's software developers. Businesses achieve goals faster, developers find rewarding projects. End-to-end support for success.",
  canonical: "",
};

export const Home = () => {
  useEffect(() => {
    const ele = document.getElementById("main-d");
    ele.scrollIntoView();
  }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d" className="home-main">
        <Layout>
          <div className="main-item">
            <RightImage ComponentData={homeData?.first} />
          </div>
          {/* <WhatWeDo ComponentData={homeData?.whatwedo} /> */}
          <WhatweDoNew ComponentData={homeData?.whatWeDoNew} />
          {/* <OurServices data={homeData?.third} /> */}
          <OurServicesNew ComponentData={homeData?.ourServicesNew} />
          <WhatWeOfferHome features={homeData?.features} />
          <HiringProcess ComponentData={homeData?.hiring} />
          <BlogsMain ComponentData={homeData?.blogs} />
          <Faqs faqData={homeData?.faqs} />
          <Banner  data={homeData?.banner}/>
        </Layout>
      </div>
    </>
  );
};

// const OurServices = ({ data }) => {
//   return (
//     <div className="services-main">
//       <div className="header">{data?.heading}</div>
//       <div className="items-main">
//         {data?.data?.map((item, index) => (
//           <OurServiceItems item={item} key={index} />
//         ))}
//       </div>
//     </div>
//   );
// };

// const OurServiceItems = ({ item }) => {
//   const navigate = useNavigate();
//   const [showContent, setShowContent] = useState(false);

//   const handleDivClick = (url) => {
//     navigate(url);
//   };

//   const handleButtonClick = (event) => {
//     event.stopPropagation(); // Prevent click event from reaching the div
//     setShowContent(true);
//   };

//   useEffect(() => {
//     function handleResize() {
//       if (window.innerWidth > 650) {
//         setShowContent(true);
//       } else {
//         setShowContent(false);
//       }
//     }

//     window.addEventListener("resize", handleResize);
//     handleResize(); // Call once initially

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   return (
//     <div className="item" onClick={() => handleDivClick(item?.boxUrl)}>
//       <div className="item-image">
//         <img src={item.imgLink} alt="" />
//       </div>
//       <div className="content">
//         <div className="title">{item.title}</div>
//         <div className={showContent ? "desc" : "descCollapsed"}>
//           {item.desc}
//         </div>
//         {!showContent && (
//           <div className="read-more" onClick={handleButtonClick}>
//             Read More
//           </div>
//         )}

//         <div className="learn-more">
//           {item?.buttons?.map((buttonztext, subIndex) => (
//             <button
//               key={subIndex}
//               onClick={() => navigate(buttonztext?.link)}
//               className="last-btn"
//             >
//               <span>{buttonztext?.title}</span>
//               <span>
//                 <img src={rightArrow} alt="" />
//               </span>
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
