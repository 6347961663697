import React from 'react';
import { Box, Avatar,useMediaQuery,useTheme } from '@mui/material';

import { keyframes } from '@emotion/react'; // Required for defining keyframes
import { useClientLandingPageInfo } from '../../../ClientLandingPageInfo/ClientLandingPageInfo';

// Define keyframes for the animation
const insideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Slide1Right = ({data}) => {
  const ClientlandingPageInfo=useClientLandingPageInfo();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        // height: '400px',
        display: 'flex',
        justifyContent:isMobile?"start": 'end',
        alignItems: 'end',
        backgroundColor: 'white',
        
      }}
    >
      {/* Rectangles (Behind the Card) */}
     { !isMobile && 
     <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px', // Adjust spacing between rectangles
          left: '80px', // Align rectangles close to the card
          top: '75%', // Adjust vertical alignment
          transform: 'translateY(-50%)', // Center vertically
          zIndex: 0, // Place rectangles behind the card
        }}
      >
        {[...Array(3)].map((_, index) => (
          <Box
            key={index}
            sx={{
              width: `${index === 2 ? 120 : 150 - index * 30}px`, // Decrease total width for each subsequent rectangle
              height: '30px', // Keep height constant
              borderRadius: '32px',
              background: '#E1DAFE',
              // boxShadow:
              //   '-2px -2px 4px 0px rgba(33, 33, 33, 0.12), 2px 2px 4px 0px rgba(33, 33, 33, 0.12)',
              position: 'relative',
              left: `${index * 10}px`, // Shift each rectangle outward dynamically
              animation: `${insideOut} 1.5s ease-in-out ${index * 0.75}s infinite`, // Staggered animation
            }}
          />
        ))}
      </Box>}

      {/* Card */}
      <Box
        sx={{
          position: 'relative',
          minWidth: {md:'515px',xs:"330px"},
          height: {md:'300px',xs:"220px"},
          borderRadius: '12px',
          background: '#E1DAFE',
          boxShadow:
            '-2px -2px 4px 0px rgba(33, 33, 33, 0.12), 2px 2px 4px 0px rgba(33, 33, 33, 0.12)',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Center Image */}
        <Box
          component="img"
          src={ClientlandingPageInfo?.whatWeDo?.Slide1}
          alt="Center Image"
          sx={{
            width: {md:'326px',xs:"230px"},
            height: {md:'300px',xs:'210px'},
            objectFit: 'cover',
          }}
        />

        {/* Left Avatar */}
        <Avatar
           src={ClientlandingPageInfo?.whatWeDo?.Slide11}
          alt="Left Avatar"
          sx={{
            position: 'absolute',
            left: '5px',
            top: '40%',
            width: '50px',
            height: '50px',
            border: '3px solid #fff',
          }}
        />

        {/* Top Right Avatar */}
        <Avatar
            src={ClientlandingPageInfo?.whatWeDo?.Slide12}
          alt="Top Right Avatar"
          sx={{
            position: 'absolute',
            right: '5px',
            top: '5%',
            width: '50px',
            height: '50px',
            border: '3px solid #fff',
          }}
        />

        {/* Bottom Right Avatar */}
        <Avatar
            src={ClientlandingPageInfo?.whatWeDo?.Slide13}
          alt="Bottom Right Avatar"
          sx={{
            position: 'absolute',
            right: '5px',
            bottom: '10%',
            width: '50px',
            height: '50px',
            border: '3px solid #fff',
          }}
        />
      </Box>
    </Box>
  );
};

export default Slide1Right;
