import { useFetchAzureAssets } from "../../../hooks/useFetchAzureAssets";
import { devQues } from "../../../mockData/devQuestions";
import { clientQues } from "../../../mockData/clientQuestions";
// import Slide1 from "../../../../../Assets/landing_page/slide1.svg";
// import Slide11 from "../../../../../Assets/landing_page/slide11.svg";
// import Slide12 from "../../../../../Assets/landing_page/slide12.svg";
// import Slide13 from "../../../../../Assets/landing_page/slide13.svg";
// import TechHiring1 from "../../../../Assets/landing_page/TechHiring1.svg";
// import TechHiring2 from "../../../../Assets/landing_page/TechHiring2.png";
// import TechHiring3 from "../../../../Assets/landing_page/TechHiring3.svg";
// import TechHiring4 from "../../../../Assets/landing_page/TechHiring4.png";
// import TechHiring5 from "../../../../Assets/landing_page/TechHiring5.svg";

// import Js1 from "../../../../Assets/landing_page/js1.svg";
// import Js2 from "../../../../Assets/landing_page/js2.svg";
// import Js3 from "../../../../Assets/landing_page/js3.svg";
// import Js4 from "../../../../Assets/landing_page/js4.svg";
// import Js5 from "../../../../Assets/landing_page/js5.svg";
// import Js6 from "../../../../Assets/landing_page/js6.svg";
// import Js7 from "../../../../Assets/landing_page/js7.svg";
// import Js8 from "../../../../Assets/landing_page/js8.svg";
// import Js9 from "../../../../Assets/landing_page/js9.svg";
// import Js10 from "../../../../Assets/landing_page/js10.svg";
// import { useNavigate } from "react-router-dom";



export const useClientLandingPageInfo = () => {
    const assets = useFetchAzureAssets(); // Fetch assets dynamically
    console.log(assets,"assets");

    return {
        section1: {
            heading1: "CONNECT",
            heading2: "INNOVATE",
            heading3: "SCALE",
            description: "Experience the future of on-demand talent with Zeero.",
            buttonText1: "Explore Opportunities",
            buttonText2: "Access Top Talent",
            buttonUrl1: "/register",
            buttonurl2: "/signup-client",
            videoUrl: assets["Assets/home_client/new-icons/bannervideo3.mp4"] || "",
            videoUrlMobile: assets["Assets/home_client/new-icons/mobileLandingVideo.mp4"] || "",
            image: assets["Assets/landing_page/main.svg"] || "",
        },

        whatWeDo:{
            Slide1:assets["Assets/landing_page/slide1.svg"],
            Slide11:assets["Assets/landing_page/slide11.svg"],
            Slide12:assets["Assets/landing_page/slide12.svg"],
            Slide13:assets["Assets/landing_page/slide13.svg"],
            Slide2:assets["Assets/landing_page/slide2.png"],
            Slide3:assets["Assets/landing_page/slide3.png"]


        },

        ourServicesNew: {
            heading: "Why Choose Zeero?",
            cards: [
                {
                    image: assets["Assets/landing_page/wcu1.svg"] || "",
                    title: "Customized Solutions for Your Projects",
                    desc: "Zeero offers personalized solutions tailored to the unique needs of each project. Whether you require on-site, hybrid or remote developers, our platform matches you with the right talent to ensure project success. With Zeero, you can expect customized solutions that align with your project requirements and objectives.  ",
                },
                {
                    image: assets["Assets/landing_page/wcu2.svg"] || "",
                    title: "Global Opportunities for Developers",
                    desc: "Zeero provides developers with access to a diverse range of projects and clients, fostering continuous learning and skill development. Joining Zeero offers opportunities to showcase your skills, expand your portfolio, and advance your career in a supportive and collaborative environment. ",
                },
                {
                    image: assets["Assets/landing_page/wcu3.svg"] || "",
                    title: "End-to-End Partnership",
                    desc: "Zeero provides a supportive environment where both clients and developers can thrive, offering ongoing guidance, support, and resources to maximize project success and career growth. With Zeero, you can rely on us to be your dedicated partner every step of the way. ",
                },
            ],
        },
        hiringSteps:{
        arr : [
            {
              step: "01",
              title: "Define your needs",
              description: "Share your project goals, timeline, and skill requirements.",
              icon: assets["Assets/landing_page/TechHiring6.svg"],
            },
            {
              step: "02",
              title: "Match with top talent",
              description: "We curate profiles from our vetted pool to find the best fit.",
              icon: assets["Assets/landing_page/TechHiring4.svg"],
            },
            {
              step: "03",
              title: "Review and select",
              description: "You review and select the most suitable candidates.",
              icon: assets["Assets/landing_page/TechHiring3.svg"],
            },
            {
              step: "04",
              title: "Smooth onboarding",
              description: "Zeero handles the onboarding for a seamless transition.",
              icon: assets["Assets/landing_page/TechHiring1.svg"],
            },
            
          ],
          TechHiring5:assets["Assets/landing_page/TechHiring5.svg"]

        },

        jobSpeaks : {
            jobSeekers: [
              { icon: assets["Assets/landing_page/js1.svg"], title: "Career growth made simple", description: "Work on exciting projects with top companies worldwide." },
              { icon: assets["Assets/landing_page/js2.svg"], title: "Global opportunities", description: "Break barriers and collaborate with teams across the globe." },
              { icon: assets["Assets/landing_page/js3.svg"], title: "Hassle-free process", description: "Our streamlined vetting process gets you the right match faster." },
              { icon: assets["Assets/landing_page/js4.svg"], title: "Flexibility at its best", description: "Choose projects that align with your skills, preferences, and goals." },
              { icon: assets["Assets/landing_page/js5.svg"], title: "Dedicated Support", description: "From onboarding to project success, we’re here for you 24/7." }
            ],
            
            recruiters: [
              { icon: assets["Assets/landing_page/js6.svg"], title: "Top-tier developers", description: "Access a pool of rigorously vetted, highly skilled professionals." },
              { icon: assets["Assets/landing_page/js7.svg"], title: "Tailored solutions", description: "Whether short-term, long-term, or one-off projects, we’ve got you covered." },
              { icon: assets["Assets/landing_page/js8.svg"], title: "Effortless hiring", description: "Say goodbye to tedious processes - we handle everything from vetting to onboarding." },
              { icon: assets["Assets/landing_page/js9.svg"], title: "Global reach", description: "Build diverse, inclusive teams with talent from around the world." },
              { icon: assets["Assets/landing_page/js10.svg"], title: "Ongoing support", description: "Need help? Our team is just a click away to assist you every step of the way." }
            ]
          },

          resultSpeaks:{
            
 stats : [
  { title: "Supports in", number: "50+", description: "Countries" },
  { title: "Trusted by", number: "500+", description: "Customers" },
  { title: "Over", number: "2K+", description: "Engineers evaluated" },
  { title: "Hire engineers", number: "2X", description: "Faster" }
],
ResultSpeakBG:assets["Assets/landing_page/ResultSpeakBG.svg"]
          },

          blogs:{
           
 blogs: [
  {
    image: assets["Assets/landing_page/blog1.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "Challenges and solutions of hiring software developers",
    link: "https://medium.com/@zeero.us/challenges-and-solutions-of-hiring-developers-in-2024-a53e188e327b",
  },
  {
    image: assets["Assets/landing_page/blog2.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "How Zeero ensures fair and reliable developer assessments?",
    link: "https://medium.com/@zeero.us/best-practices-for-proctoring-how-zeero-ensures-fair-and-reliable-developer-assessments-f5d2f298fcc8",
  },
  {
    image: assets["Assets/landing_page/blog3.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "How to build a successful career as a freelance developer",
    link: "https://medium.com/@zeero.us/how-to-build-a-successful-career-as-a-freelance-developer-cd3644549379",
  },
  {
    image: assets["Assets/landing_page/blog4.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "The future of software development: How tech businesses can adapt and succeed?",
    link: "https://medium.com/@zeero.us/the-future-of-software-development-how-tech-businesses-can-adapt-and-succeed-f4faa58521af",
  },
  {
    image: assets["Assets/landing_page/blog5.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "How to build a diverse and inclusive software development team?",
    link: "https://medium.com/@zeero.us/how-to-build-a-diverse-and-inclusive-software-development-team-1ef525c8a6ef",
  },
  {
    image: assets["Assets/landing_page/blog6.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "How to identify skill gaps and implement effective upskilling initiatives?",
    link: "#",
  },
  {
    image: assets["Assets/landing_page/blog7.svg"]|| "",
    date: "May 12, 2024",
    readTime: "5 min read",
    title: "Why infrastructure as code (IaC) is the future of software development?",
    link: "https://medium.com/@zeero.us/why-infrastructure-as-code-iac-is-the-future-of-software-development-0a935d470d8a",
  },
]
          },

          technology:{
             techSkills : [
                { name: "Python", side: "left", top: "10%",left:"8%", gradient: "linear-gradient(to bottom right, #17B3A9 0%, #0945DF 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #17B3A9 0%, #0945DF 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #17B3A9 0%, #0945DF 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #17B3A9 0%, #0945DF 50%) top right / 50% 50% no-repeat" },
                { name: "ML", side: "left", top: "19%",left:"25%", gradient: "linear-gradient(to bottom right, #7C9CEE 0%, #5D2FA8 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #7C9CEE 0%, #5D2FA8 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #7C9CEE 0%, #5D2FA8 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #7C9CEE 0%, #5D2FA8 50%) top right / 50% 50% no-repeat;" },
                { name: "Java", side: "left", top: "79%",left:"15%", gradient: "linear-gradient(to bottom right, #D2428F 0%, #B842C5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #D2428F 0%, #B842C5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #D2428F 0%, #B842C5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #D2428F 0%, #B842C5 50%) top right / 50% 50% no-repeat;" },
                { name: "Node JS", side: "left", top: "40%", left:"10%",gradient: "linear-gradient(to bottom right, #8374D9 0%, #C55273 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #8374D9 0%, #C55273 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #8374D9 0%, #C55273 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #8374D9 0%, #C55273 50%) top right / 50% 50% no-repeat;" },
                { name: "AI", side: "right", top: "14%", left:"10%",right:'9%',gradient: "linear-gradient(to bottom right, #D2428F 0%, #B842C5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #D2428F 0%, #B842C5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #D2428F 0%, #B842C5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #D2428F 0%, #B842C5 50%) top right / 50% 50% no-repeat;" },
                { name: "Javascript", side: "right", top: "20%",left:"10%",right:'26%', gradient: "linear-gradient(to bottom right, #D2428F 0%, #B842C5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #D2428F 0%, #B842C5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #D2428F 0%, #B842C5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #D2428F 0%, #B842C5 50%) top right / 50% 50% no-repeat;" },
                { name: "PHP", side: "right", top: "2%",left:"10%",right:'19%', gradient: "linear-gradient(to bottom right, #3986E1 0%, #75D5F3 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #3986E1 0%, #75D5F3 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #3986E1 0%, #75D5F3 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #3986E1 0%, #75D5F3 50%) top right / 50% 50% no-repeat;" },
                { name: "Angular JS", side: "right", top: "50%",left:"10%",right:'7%',  gradient: "linear-gradient(to bottom right, #6BCFFA 0%, #4473F6 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #6BCFFA 0%, #4473F6 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #6BCFFA 0%, #4473F6 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #6BCFFA 0%, #4473F6 50%) top right / 50% 50% no-repeat;" },
                { name: "SQL", side: "left", top: "45%", left:"21%",gradient: "linear-gradient(to bottom right, #D2428F 0%, #B842C5 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #D2428F 0%, #B842C5 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #D2428F 0%, #B842C5 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #D2428F 0%, #B842C5 50%) top right / 50% 50% no-repeat;" },
                { name: "Data Science", side: "right", top: "78%", left:"10%",right:'10%', gradient: "linear-gradient(to bottom right, #56D9FC 0%, #7FD0CA 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #56D9FC 0%, #7FD0CA 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #56D9FC 0%, #7FD0CA 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #56D9FC 0%, #7FD0CA 50%) top right / 50% 50% no-repeat;" },
                { name: "React JS", side: "right", top: "47%",left:"10%", right:'20%', gradient: "linear-gradient(to bottom right, #17B3A9 0%, #0945DF 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #17B3A9 0%, #0945DF 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #17B3A9 0%, #0945DF 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #17B3A9 0%, #0945DF 50%) top right / 50% 50% no-repeat;" },
                { name: "Kotlin", side: "left", top: "72%", left:"6%",gradient: "linear-gradient(to bottom right, #7C9CEE 0%, #5D2FA8 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #7C9CEE 0%, #5D2FA8 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #7C9CEE 0%, #5D2FA8 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #7C9CEE 0%, #5D2FA8 50%) top right / 50% 50% no-repeat;" },
                { name: "Bitbucket", side: "right", top: "78%",left:"10%", right:'24%',gradient: "linear-gradient(to bottom right, #8374D9 0%, #C55273 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #8374D9 0%, #C55273 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #8374D9 0%, #C55273 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #8374D9 0%, #C55273 50%) top right / 50% 50% no-repeat;" },
                { name: "Typescript", side: "left", top: "71%", left:"24%",gradient: "linear-gradient(to bottom right, #3986E1 0%, #75D5F3 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #3986E1 0%, #75D5F3 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #3986E1 0%, #75D5F3 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #3986E1 0%, #75D5F3 50%) top right / 50% 50% no-repeat;" }
              ],
              
              illu:assets["Assets/landing_page/TechnologyIllustration.svg"]
          },
          

        features: {
            title: "What We Offer?",
            data: [
                {
                    title: "Access to Top Talent",
                    description:"Access top-notch software developers on-demand for your projects. Our developers undergo extensive tests, project submissions, and interviews, ensuring their expertise in the industry. ",
                    image: assets["Assets/home/offer/whatweoffer1.png"] || "",
                },
                {
                    title: "Cost-Effective Solutions",
                    description: "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth. ",
                    image: assets["Assets/home/offer/whatweoffer2.png"] || "",
                },
                {
                    title: "Strategic Partnership",
                    description: "We go beyond simply connecting you with talent. We're your strategic partner, supporting you as a solution to the challenges you face while resource planning.",
                    image: assets["Assets/home/offer/whatweoffer3.png"] || "",
                },
                {
                    title: "Diversity and Inclusion",
                    description:"Build stronger, more inclusive teams with Zeero's global talent pool, accessing a wide range of perspectives and experiences to promote creativity, innovation, and collaboration.",
                    image: assets["Assets/home/offer/whatweoffer4.png"] || "",
                },
            ],
        },

        // zeeroAIPlatformData: {
        //     backgroundImageUrl: assets["Assets/home_client/new-icons/chatBotVideo.mp4"] || "",
        //     backgroundImageUrlMobile:assets["Assets/home_client/new-icons/chatBotVideoMobile.mp4"] || "",
        //     line1: "Discover the Power of Zeero’s AI Platform",
        //     line2: "Experience Zeero’s versatile AI-powered hiring tool...",
        //     buttonText: "Hire Top Talent",
        // },

        faqs: {
            title: "Frequently Asked Questions",
            mainData: [
                {
                    header: "For Client",
                    questions: clientQues,
                },
                {
                    header: "For Developers",
                    questions: devQues,
                },
            ],
        },

        testimonialsSliderData: {
            backgroundImageUrl: assets["Assets/home_client/new-icons/BG.webp"] || "",
            title: "Don’t just take our word for it",
            testimonials: [
                {
                    title: "Exceptionally streamlined",
                    desc: ` Zeero’s assessment process was rigorous but fair. 
It gave me the opportunity to showcase both my technical and soft skills, and I appreciated the transparency and feedback provided throughout the process. The experience helped me grow as a developer, and I would highly recommend it to anyone looking for a challenging yet fulfilling interview process.`,
                    authorName: "Tochukwu Udochukwu",
                    authorTitle: "Frontend Developer",
                    authorImage: assets["Assets/home_client/new-icons/confession1.png"] || "",
                },
                {
                    title: "Highly recommended",
                    desc: `After signing up on Zeero, I embarked on an intense assessment that lasted over two hours. The experience was both challenging and rewarding, and I successfully passed this stage, moving forward to the next step—an HR interview. The interaction during the interview was engaging and insightful.`,
                    authorName: "Chukwuebuka Nwaturuba",
                    authorTitle: "Backend Developer",
                    authorImage: assets["Assets/home_client/new-icons/confession2.png"] || "",
                },
                {
                    title: "Great experience",
                    desc: `The recruitment process was rigorous but offered a balanced and fair assessment of my technical and interpersonal skills. It was an incredible learning experience that contributed to my growth as a professional. Also, this allowed me to share insights into my thought processes, coding practices, and the depth of my problem-solving skills.`,
        authorName: "Vishnu Vardhan Chakka",
                    authorTitle: "Backend Developer",
                    authorImage: assets["Assets/home_client/new-icons/confession3.png"] || "",
                },
                {
                    title: "Pleasant experience",
                    desc: `The entire process was intensive yet seamless. What stood out to me was the constant human interaction I experienced with the Zeero team, something uncommon in other recruitment platforms. The staff was highly responsive and always available whenever I reached out. I highly recommend Zeero for engineers looking for a transparent and recruitment process.`,
                    authorName: "Jatin Verma",
                    authorTitle: "Frontend Developer",
                    authorImage: assets["Assets/home_client/new-icons/confession4.png"] || "",
                },
            ],
        },

        zeeroAIPlatformData: {
            backgroundImageUrl: assets["Assets/home_client/new-icons/chatBotVideo.mp4"] || "",
            backgroundImageUrlMobile: assets["Assets/home_client/new-icons/chatBotVideoMobile.mp4"] || "",
            line1: "Discover the power of Zeero’s AI platform",
            line2: "Experience Zeero’s versatile AI-powered hiring tool...",
            buttonText: "Hire Top Talent",
        },

        banner:{
            heading:"Unlock Top Talent -",
            heading2:"Partner Now!",
            desc:"Access a global network of skilled developers, ready to contribute to your projects. Let Zeero help you find the perfect fit.",
            buttonText:'Hire Talent',
            navigateTo:"/client-login"
        }
    };
};
