import candidate1 from "../../../../Assets/home/candidatesphoto/candidate1.svg"
import candidate2 from "../../../../Assets/home/candidatesphoto/candidate2.svg"
import candidate3 from "../../../../Assets/home/candidatesphoto/candidate3.svg"
import candidate4 from "../../../../Assets/home/candidatesphoto/candidate4.svg"
import candidate5 from "../../../../Assets/home/candidatesphoto/person2.webp"
const candidate6="https://randomuser.me/api/portraits/men/30.jpg"
const candidate7="https://randomuser.me/api/portraits/women/30.jpg"

const mask_matched_profiles= [
        {
            "availability": "Immediate",
            "developerName": "Archie Rix",
            "email": "product.test@mailinator.com",
            "experienceInYears": "5-7 years",
            "industryDomain": "Retail",
            "role":"Software Developer",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "location": "USA",
            "noticePeriod": null,
            image:candidate1,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "Suheab",
            "email": "product.test@mailinator.com",
            "experienceInYears": "2-3 years",
            "industryDomain": "Information Service",
            "role":"Backend Developer",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "location": "Africa",
            "noticePeriod": null,
            image:candidate5,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "Shruthi",
            "email": "product.test@mailinator.com",
            "experienceInYears": "3-5 years",
            "industryDomain": "Ecommerece",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "role":"Front Developer",
            "location": "India",
            "noticePeriod": null,
            image:candidate3,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "Smriti",
            "email": "product.test@mailinator.com",
            "experienceInYears": "1-3 years",
            "industryDomain": "Healthcare",
            "joiningDate": "2024-09-12",
            "role":"QA Analayst",
            "lastWorkingDate": null,
            "location": "India",
            "noticePeriod": null,
            image:candidate4,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "Harish",
            "email": "product.test@mailinator.com",
            "experienceInYears": "5-9 years",
            "role":"AI Engineer",
            "industryDomain": "Information Service",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "location": "Africa",
            "noticePeriod": null,
            image:candidate2,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "Alex",
            "email": "product.test@mailinator.com",
            "experienceInYears": "3-5 years",
            "role":"ML Developer",
            "industryDomain": "Retail",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "location": "Africa",
            "noticePeriod": null,
            image:candidate7,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        {
            "availability": "Immediate",
            "developerName": "John",
            "email": "product.test@mailinator.com",
            "role":"ML Developer",
            "experienceInYears": "5-6 years",
            "industryDomain": "Information Service",
            "joiningDate": "2024-09-12",
            "lastWorkingDate": null,
            "location": "Africa",
            "noticePeriod": null,
            image:candidate6,
            "skills": [
                "MySQL",
                "Mongo DB",
                "Spring Boot",
                "HTML",
                "CSS",
                "Bootstrap",
                "Kafka",
                "React js",
                "Java",
                "C++",
                "Java Script",
                "Git",
                "Jira",
                "Multithreading",
                "Azure",
                "Docker",
                "Jenkins",
                "Figma",
                "OOPS",
                "SQL",
                "DBMS",
                "OS"
            ]
        },
        
    ]

 export default mask_matched_profiles