import React from 'react';
import { Box,useTheme,useMediaQuery } from '@mui/material';

import { useClientLandingPageInfo } from '../../../ClientLandingPageInfo/ClientLandingPageInfo';
const Slide2Right = () => {
  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view
      const ClientlandingPageInfo=useClientLandingPageInfo();
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        //height: '400px',
        display: 'flex',
        justifyContent:isMobile?"start": 'end',
        alignItems: 'end',
        backgroundColor: 'white',
      }}
    >
      {/* Square of Dots with Horizontal Animation */}
     { !isMobile && <Box
        sx={{
          position: 'absolute',
          top: '-74px', // Adjust position relative to the top
          right: '10px', // Adjust position relative to the right
          width: '150px', // Width of the dotted square
          height: '150px', // Height of the dotted square
          backgroundImage: 'radial-gradient(#D4FFDF 7px, transparent 1px)', // Dotted pattern
          backgroundSize: '30px 30px', // Spacing between dots
          zIndex: 0, // Place behind the card
          animation: 'moveLeftRight 3s ease-in-out infinite', // Horizontal movement animation
        }}
      ></Box>}

      {/* Card */}
      <Box
        sx={{
          position: 'relative',
          minWidth: {md:'515px',xs:"330px"},
          height: {md:'320px',xs:"220px"},
          borderRadius: '12px',
          background: '#D4FFDF',
          boxShadow:
            '-2px -2px 4px 0px rgba(33, 33, 33, 0.12), 2px 2px 4px 0px rgba(33, 33, 33, 0.12)',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Center Image */}
        <Box
          component="img"
          src={ClientlandingPageInfo?.whatWeDo?.Slide2}
          alt="Center Image"
          sx={{
            width: {md:'373px',xs:'250px'},
            height: {md:'310px',xs:'208px'},
            //objectFit: 'cover',
          }}
        />
      </Box>

      {/* Animation Keyframes */}
      <style>
        {`
          @keyframes moveLeftRight {
            0% {
              transform: translateX(0); /* Start at original position */
            }
            50% {
              transform: translateX(-50px); /* Move 50px to the left */
            }
            100% {
              transform: translateX(0); /* Move back to the original position */
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Slide2Right;
