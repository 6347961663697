import React from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
// import Js1 from "../../../../Assets/landing_page/js1.svg";
// import Js2 from "../../../../Assets/landing_page/js2.svg";
// import Js3 from "../../../../Assets/landing_page/js3.svg";
// import Js4 from "../../../../Assets/landing_page/js4.svg";
// import Js5 from "../../../../Assets/landing_page/js5.svg";
// import Js6 from "../../../../Assets/landing_page/js6.svg";
// import Js7 from "../../../../Assets/landing_page/js7.svg";
// import Js8 from "../../../../Assets/landing_page/js8.svg";
// import Js9 from "../../../../Assets/landing_page/js9.svg";
// import Js10 from "../../../../Assets/landing_page/js10.svg";
import { useNavigate } from "react-router-dom";

// const jobSeekers = [
//   { icon: Js1, title: "Career growth made simple", description: "Work on exciting projects with top companies worldwide." },
//   { icon: Js2, title: "Global opportunities", description: "Break barriers and collaborate with teams across the globe." },
//   { icon: Js3, title: "Hassle-free process", description: "Our streamlined vetting process gets you the right match faster." },
//   { icon: Js4, title: "Flexibility at its best", description: "Choose projects that align with your skills, preferences, and goals." },
//   { icon: Js5, title: "Dedicated Support", description: "From onboarding to project success, we’re here for you 24/7." }
// ];

// const recruiters = [
//   { icon: Js6, title: "Top-tier developers", description: "Access a pool of rigorously vetted, highly skilled professionals." },
//   { icon: Js7, title: "Tailored solutions", description: "Whether short-term, long-term, or one-off projects, we’ve got you covered." },
//   { icon: Js8, title: "Effortless hiring", description: "Say goodbye to tedious processes - we handle everything from vetting to onboarding." },
//   { icon: Js9, title: "Global reach", description: "Build diverse, inclusive teams with talent from around the world." },
//   { icon: Js10, title: "Ongoing support", description: "Need help? Our team is just a click away to assist you every step of the way." }
// ];

const JobSeekers = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate=useNavigate()

  return (
    <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", width: "100%" }}>
      {/* Job Seekers Section */}
      <Box sx={{ flex: 1, p: 4, backgroundColor: "#E7F0FF" }}>
        <Typography sx={{ color: "#212121", textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: { xs: "16px", md: "18px" },fontWeight: 600, letterSpacing: "0.2px" }}>
          Got talent?
        </Typography>
        <Typography sx={{ color: "#212121",  textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: { xs: "16px", md: "18px" }, fontWeight: 700, letterSpacing: "0.2px" }}>
          Why job seekers love us
        </Typography>
        {data?.jobSeekers.map((item, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2, mt:{md:6,xs:3}}}>
            <Box sx={{ width: "44px", height: "44px", minWidth: "44px", minHeight: "44px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#B3E5FC" }}>
              <img src={item.icon} alt={item.title} style={{ width: "70%", height: "70%" }} />
            </Box>
            <Typography sx={{ color: "#0D47A1", fontFamily: "Poppins", fontSize: "13px", fontWeight: 700, letterSpacing: "0.2px" }}>
              {item.title}: <span style={{ color: "#4F4F4F", fontWeight: 600 }}>{item.description}</span>
            </Typography>
          </Box>
        ))}
        <Typography sx={{ color: "#212121", textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: "14px", fontWeight: 500, letterSpacing: "0.2px", marginTop: "40px" }}>
          Start your journey toward fulfilling and rewarding opportunities today with Zeero!
        </Typography>
        <Button   onClick={()=>{
    navigate("/login")
  }} variant="contained" sx={{ mt: 3, backgroundColor: "#0B52D4", color: "#FFF", borderRadius: "8px", display: isMobile ? "flex" : "inline-flex", mx: isMobile ? "auto" : "0" }}>Sign Up</Button>
      </Box>

      {/* Recruiters Section */}
      <Box sx={{ flex: 1, p: 4, backgroundColor: "#E7FFE7" }}>
        <Typography sx={{ color: "#212121", textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: { xs: "16px", md: "18px" }, fontWeight: 600, letterSpacing: "0.2px" }}>
          Need talent?
        </Typography>
        <Typography sx={{ color: "#212121",  textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: { xs: "16px", md: "18px" }, fontWeight: 700, letterSpacing: "0.2px" }}>
          Why recruiters love us
        </Typography>
        {data?.recruiters?.map((item, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 2, mt:{md:6,xs:3}}}>
            <Box sx={{ width: "44px", height: "44px", minWidth: "44px", minHeight: "44px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#C8E6C9" }}>
              <img src={item.icon} alt={item.title} style={{ width: "70%", height: "70%" }} />
            </Box>
            <Typography sx={{ color: "#2E7D32", fontFamily: "Poppins", fontSize: "13px", fontWeight: 700, letterSpacing: "0.2px" }}>
              {item.title}: <span style={{ color: "#4F4F4F", fontWeight: 600 }}>{item.description}</span>
            </Typography>
          </Box>
        ))}
        <Typography sx={{ color: "#212121",  textAlign:isMobile?"center":"start", fontFamily: "Poppins", fontSize: "14px", fontWeight: 500, letterSpacing: "0.2px", marginTop: "40px" }}>
          Experience hiring that’s fast, effective, and stress-free with Zeero!
        </Typography>
        <Button
  variant="contained"
  sx={{
    mt: 3,
    backgroundColor: "#34A853", // Default background
    color: "#FFF",
    borderRadius: "8px",
    display: isMobile ? "flex" : "inline-flex",
    mx: isMobile ? "auto" : "0",
    "&:hover": {
      backgroundColor: "#4D8E50", // Hover effect
    },
  }}
  onClick={()=>{
    navigate("/client-login")
  }}
>
  Sign Up
</Button>

     
      </Box>
    </Box>
  );
};

export default JobSeekers;
